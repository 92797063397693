import React from "react";

import { useMsal } from "@azure/msal-react";
import { Camera } from "phosphor-react";
import { useDispatch, useSelector } from "react-redux";

import Button from "~/components/button/Button";
import FieldCard from "~/components/cards/FieldCard";
import InfoCard from "~/components/cards/InfoCard";
import RecapCard from "~/components/cards/RecapCard";
import StringHelper from "~/helpers/StringHelper";
import useLayout from "~/hooks/useLayout";
import useMount from "~/hooks/useMount";
import { updateUserInfos } from "~/store/user/userSlice";

function MyProfile() {
  const msal = useMsal();
  const { setLayout } = useLayout();
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const updateInfos = () => {
    dispatch(updateUserInfos());
  };

  useMount(() => {
    setLayout({
      title: "MrProfile",
      bkgImage: "waveSimple2",
    });
  });

  let activeAccount = msal.instance.getActiveAccount();
  if (!activeAccount) {
    msal.instance.setActiveAccount(msal.instance.getAllAccounts()[0]);
    activeAccount = msal.instance.getActiveAccount();
  }

  const { family_name: lastName, given_name: firstName } =
    activeAccount?.idTokenClaims;

  return (
    <div className="flex flex-col space-y-16 px-8 md:px-28 mb-16">
      <div className="flex flex-row space-x-12 align-top">
        <div className="relative">
          <div className="relative flex flex-shrink-0 h-52 w-52 rounded-full border-2 border-white shadow-xl overflow-hidden">
            <img
              src={`/avatars/${user?.avatar}`}
              alt="avatar"
              className="rounded-full border border-black"
            />
          </div>
          <div className="absolute top-40 right-6 p-2 shadow-xl bg-white rounded-full hover:cursor-pointer">
            <Camera size={28} />
          </div>
        </div>
        <div className="flex flex-col space-y-10">
          <div className="flex flex-col">
            <p className="font-bold text-sm">Profil utilisateur</p>
            <p className="font-bold text-4xl">
              {firstName} {lastName}
            </p>
          </div>
          <Button onClick={updateInfos}>Modifier</Button>
        </div>
      </div>

      <RecapCard title="Mon entourage">
        {user.entourage.length === 0 && (
          <div className="col-span-full">
            Vous n'avez enregistré aucune personne de confiance pour le moment.
          </div>
        )}
        {user.entourage.map((person) => (
          <InfoCard key={person.id} title={person.relationshipType.label}>
            <FieldCard title="Nom">{person?.lastName}</FieldCard>
            <FieldCard title="Prénom">{person?.firstName}</FieldCard>
            <FieldCard title="Email">{person?.email}</FieldCard>
            <div />
            <FieldCard title="Téléphone">
              {StringHelper.formatPhoneNumber(person?.mobilePhone)}
            </FieldCard>
          </InfoCard>
        ))}
      </RecapCard>
    </div>
  );
}

export default MyProfile;
