import React, { useEffect } from "react";

import Block from "~/components/block/Block";
import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import FormGroup from "~/components/forms/formGroup/FormGroup";
import Label from "~/components/forms/label/Label";
import ValidationCheckbox from "~/components/forms/validationCheckbox/ValidationCheckbox";
import ValidationForm from "~/components/forms/validationForm/ValidationForm";
import ValidationInput from "~/components/forms/validationInput/ValidationInput";
import ValidationRadioButtonsGroup from "~/components/forms/validationRadioButtonsGroup/ValidationRadioButtonsGroup";

import usePread from "~/contexts/usePread";

import Comfort from "~/images/illustrations/comfort.png";
import StickyFooter from "./stickyFooter";
import Panel from "~/components/panel/Panel";
import { CaretLeft, CaretRight } from "phosphor-react";

export const wishesStepPageParameters = {
  title: "Vos souhaits de confort",
  subTitle: null,
  image: Comfort,
  imageTitle: "Confort",
  imageNoShift: true,
};

function WishesStep({ onSubmitted, onPrevious }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (formData, { setSubmitting }) => {
    setPatientRoomWishes(formData);
    onSubmitted(formData);
    setSubmitting(false);
  };

  const { roomOptions, setPatientRoomWishes } = usePread();

  return (
    <>
      <Container className="mb-32">
        <ValidationForm
          id="wishesForm"
          initialValues={{
            wantsPrivateRoom: null,
            wantsLargeRoom: null,
            hasMobilityIssues: null,
            mobilityIssues: "",
            roomOptions: [],
          }}
          onSubmit={handleSubmit}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className="flex flex-col space-y-16 justify-center w-full">
                <Panel title="Votre chambre">
                  <div className="flex flex-col space-y-8">
                    <FormGroup>
                      <Label htmlFor="wantsPrivateRoom">
                        Est-il important pour vous de bénéficier d’une chambre
                        particulière, calme et intime ?
                      </Label>
                      <ValidationRadioButtonsGroup
                        id="wantsPrivateRoom"
                        name="wantsPrivateRoom"
                        values={[
                          {
                            value: "1",
                            label: "Oui",
                          },
                          {
                            value: "0",
                            label: "Non",
                          },
                        ]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="wantsLargeRoom">
                        Est-il important pour vous de bénéficier d’un grand
                        espace dans votre chambre ?
                      </Label>
                      <ValidationRadioButtonsGroup
                        id="wantsLargeRoom"
                        name="wantsLargeRoom"
                        values={[
                          {
                            value: "1",
                            label: "Oui",
                          },
                          {
                            value: "0",
                            label: "Non",
                          },
                        ]}
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="hasMobilityIssues">
                        Avez-vous des contraintes de mobilité ? (handicap
                        physique, trouble de la vue, autres...)
                      </Label>
                      <ValidationRadioButtonsGroup
                        id="hasMobilityIssues"
                        name="hasMobilityIssues"
                        values={[
                          {
                            value: "1",
                            label: "Oui",
                          },
                          {
                            value: "0",
                            label: "Non",
                          },
                        ]}
                      />
                    </FormGroup>
                    {values.hasMobilityIssues === "1" && (
                      <FormGroup>
                        <Label htmlFor="mobilityIssues">Précisez</Label>
                        <ValidationInput
                          id="mobilityIssues"
                          name="mobilityIssues"
                        />
                      </FormGroup>
                    )}
                  </div>
                </Panel>

                <Panel
                  title="Prestations complémentaires"
                  headerContent={
                    <Label htmlFor="roomOptions">
                      Quels services complémentaires aimeriez-vous retrouver
                      dans votre chambre ?*
                    </Label>
                  }
                >
                  <div className="grid md:grid-cols-2 gap-x-8 gap-y-4">
                    {roomOptions?.map((opt) => (
                      <ValidationCheckbox
                        key={opt.id}
                        name="roomOptions"
                        value={`${opt.id}`}
                        className="border-2 rounded-lg border-yellow-500 bg-[#FFFAEF] px-4 py-8"
                      >
                        <div className="font-[DMSans-Bold] flex flex-row items-baseline gap-x-2">
                          {opt.name}
                          {opt.details && (
                            <div className="w-[18px] h-[18px] border-2 border-gray-900 rounded flex flex-grow-0 flex-shrink-0 items-center justify-center">
                              i
                            </div>
                          )}
                        </div>
                        <div className="font-[DMSans-Regular] text-[#5F6C85]">
                          {opt.description}
                        </div>
                      </ValidationCheckbox>
                    ))}
                  </div>

                  <div className="font-[DMSans-Regular] text-sm">
                    *Sous réserve de services disponibles dans votre
                    établissement le jour de votre séjour
                  </div>
                </Panel>
              </div>
            );
          }}
        </ValidationForm>
      </Container>

      <StickyFooter>
        <div className="flex flex-row items-center justify-between space-x-10 w-full lg:px-20">
          <Button
            size="s"
            color="white"
            className="flex items-center font-[Poppins-SemiBold] border space-x-2 px-12"
            startIcon={<CaretLeft size={16} />}
            onClick={onPrevious}
          >
            <p>Retour</p>
          </Button>
          <Button
            size="s"
            type="submit"
            form="wishesForm"
            className="flex-shrink-0"
            endIcon={<CaretRight size={16} />}
          >
            Valider
          </Button>
        </div>
      </StickyFooter>
    </>
  );
}

export default WishesStep;
