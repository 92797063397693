import React from "react";

import { Field } from "formik";

import FileInput from "~/components/forms_unvalidated/fileInput/FileInput";

function ValidationFileInput({
  instructions,
  description,
  onChange,
  name,
  id,
  fileTypes,
}) {
  return (
    <Field name={name}>
      {({ field, form }) => (
        <FileInput
          instructions={instructions}
          description={description}
          {...field}
          onChange={(files) => {
            form.setFieldValue(name, files[0]);
            onChange && onChange(files[0]);
          }}
          name={name}
          id={id}
          fileTypes={fileTypes}
        />
      )}
    </Field>
  );
}

export default ValidationFileInput;
