import React, { useState } from "react";

import classNames from "classnames";
import _ from "lodash";
import moment from "moment";
import { Buildings, CaretRight, Bed } from "phosphor-react";
import Carousel from "react-multi-carousel";
import { useSelector } from "react-redux";

import Button from "~/components/button/Button";
import CarouselArrows from "~/components/carousel/CarouselArrows";
import Container from "~/components/container/Container";
import PageDataLoader from "~/components/pageDataLoader/PageDataLoader";
import PageHeader from "~/components/pageHeader/PageHeader";
import Panel from "~/components/panel/Panel";
import Multi from "~/images/illustrations/multi.png";
import { useGetMedicalStays } from "~/queries/medicalStaysQueries";
import { useGetNews } from "~/queries/newsQueries";

import CarouselDot from "../../components/carousel/CarouselDot";

import NewsCarouselItem from "./components/NewsCarouselItem";

import "react-multi-carousel/lib/styles.css";

const carouselBreakpointsNews = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

function Dashboard() {
  const [displayMode, setDisplayMode] = useState("current");
  const { isLoading: newsLoading, data: news } = useGetNews();
  const { isLoading: medicalStaysLoading, data: medicalStays } =
    useGetMedicalStays();

  const user = useSelector((state) => state.user);

  const [currentMedicalStays, pastMedicalStays] = _.partition(
    medicalStays,
    (m) => moment(m.startDate)?.isSameOrAfter(moment(), "day")
  );

  return (
    <PageDataLoader isLoading={newsLoading || medicalStaysLoading}>
      <Container size="sm" className="my-24 relative">
        <div className="absolute flex justify-between  z-20">
          <PageHeader title={`Bonjour ${user.firstName} ${user.lastName}`}>
            <p className="pt-4 font-[Poppins-Regular] ">
              <span className="font-[Poppins-SemiBold] ">
                Anticipez votre séjour
              </span>{" "}
              et évitez le stress de l’arrivée avec la pré-admission en ligne.
              En cas de besoin,{" "}
              <span className="font-[Poppins-SemiBold] ">
                faites-vous aider par un proche.
              </span>
            </p>
          </PageHeader>
          <img
            src={Multi}
            alt="Illustration"
            className="shrink-0 object-contain w-1/2  px-10 hidden md:flex"
          />
        </div>

        <Panel title="Vos parcours" className="lg:mt-72 mt-36">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-x-14">
            <div className="md:col-span-2">
              <div className="flex flex-row items-stretch">
                {[
                  { label: "À venir", value: "current" },
                  { label: "Passés", value: "past" },
                ].map((b) => (
                  <div
                    onClick={() => {
                      setDisplayMode(b.value);
                    }}
                    className={classNames(
                      "flex border-b-2 px-4 font-[Poppins-SemiBold] text-xl",
                      {
                        "border-[#FDD772]": displayMode === b.value,
                        "border-[#EDEFF2] text-gray-300 hover:cursor-pointer":
                          displayMode !== b.value,
                      }
                    )}
                  >
                    {b.label}
                  </div>
                ))}
                <div className="flex flex-1 border-b-2 border-[#EDEFF2]" />
              </div>
            </div>
          </div>
          <div className="mt-8 grid grid-cols-1 lg:grid-cols-3 gap-x-6 gap-y-4 lg:gap-y-0">
            <div className="md:col-span-2">
              <div className="flex flex-col gap-y-4">
                {(displayMode === "current"
                  ? currentMedicalStays
                  : pastMedicalStays
                ).map((m) => (
                  <div
                    key={m.serialNumber}
                    className={classNames(
                      "flex lg:flex-row flex-col lg:items-center rounded-2xl border border-[#D4D9E0] p-6 gap-x-6 gap-y-6 lg:gap-y-0",
                      {
                        "bg-white": !m.preadEngaged,
                        "bg-gradient-to-tl from-[#F9B004] to-[#FBCC5C] ":
                          m.preadEngaged,
                      }
                    )}
                  >
                    <div className="flex flex-1 flex-col ">
                      <div className="flex flex-col gap-y-1">
                        <div className="flex items-center gap-x-2 font-[Poppins-SemiBold] text-xl pb-2  ">
                          <p className="">Parcours</p>
                          <p className="first-letter:uppercase">
                            {m.intervention}
                          </p>
                        </div>

                        <div className="flex items-center space-x-2">
                          <Bed size="12" />
                          <p className="text-sm font-[DMSans-Regular]">
                            Hospitalisation du{" "}
                            <span className="font-[DMSans-Bold]">
                              {moment(m.startDate).format("DD/MM/YYYY")}
                            </span>
                          </p>
                        </div>

                        <div className="flex items-center space-x-2">
                          <Buildings size="12" />
                          <p className="text-sm font-[DMSans-Regular]">
                            {m.location}
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="flex flex-col space-y-2 h-full md:w-72">
                      {m.preadAvailable && (
                        <Button
                          size="s"
                          color={m.preadEngaged ? "black" : "yellow"}
                          href={`/medicalStays/${m.serialNumber}/preadmission`}
                          variant={m.preadEngaged ? null : "outline"}
                          noShadow
                          roundedFull
                        >
                          {m.preadEngaged ? "Continuer" : "Commencer"} la
                          préadmission
                        </Button>
                      )}
                      <Button
                        size="s"
                        color="transparent"
                        variant="outline"
                        noShadow
                        href={`/medicalStays/${m.serialNumber}`}
                        endIcon={<CaretRight size={18} />}
                      >
                        Voir mon séjour
                      </Button>
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col space-y-6 bg-white border border-[#D4D9E0] rounded-2xl py-8 px-6">
              <div className="flex space-x-6">
                <div className="flex items-center justify-center bg-yellow-50 rounded-lg w-14 h-14">
                  <Buildings size={24} className="text-gr&y-600" />
                </div>
                <p className="font-[Poppins-SemiBold]">
                  Rattacher un autre établissement à mon compte Elsan
                </p>
              </div>
              <p>
                Lorem ipsum dolor sit amet consectetur. Vitae lorem volutpat
                ultrices iaculis.
              </p>
              <Button size="s" variant="outline" noShadow roundedFull>
                Rattacher un établissement
              </Button>
            </div>
          </div>
        </Panel>
      </Container>

      {news && (
        <div
          className="flex justify-center w-full lg:px-32 px-6 py-10 bg-[#F9FAFB] 
        bg-gradient-to-br from-[#344A8E] to-[#215BBE]  "
        >
          <Container className="relative">
            <div className=" mb-2">
              <div className="font-[Poppins-SemiBold] text-3xl text-white ">
                L'actualité Elsan
              </div>
            </div>
            <Carousel
              swipeable
              arrows={false}
              showDots
              customDot={<CarouselDot style="blue" />}
              responsive={carouselBreakpointsNews}
              keyBoardControl
              renderButtonGroupOutside
              customButtonGroup={<CarouselArrows />}
              containerClass="mt-8 pb-12"
              dotListClass="w-1/2 mx-auto"
              itemClass="px-4 flex self-stretch"
            >
              {news.map((n) => (
                <NewsCarouselItem key={n.id} news={n} />
              ))}
            </Carousel>
          </Container>
        </div>
      )}
    </PageDataLoader>
  );
}

export default Dashboard;
