import React, { useState } from "react";

import { useMsal } from "@azure/msal-react";
import classNames from "classnames";
import { motion, AnimatePresence, useAnimation } from "framer-motion";
import {
  CaretDown,
  CaretRight,
  FolderPlus,
  Gear,
  Question,
  SignOut,
  UserList,
} from "phosphor-react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function UserPanelButton() {
  const msal = useMsal();

  const navigate = useNavigate();
  const controls = useAnimation();
  const user = useSelector((state) => state.user);
  const [showMenu, setShowMenu] = useState(false);

  const menuUser = [
    [
      {
        icon: UserList,
        text: "Mon compte",
        path: "/myProfile",
      },
      {
        icon: FolderPlus,
        text: "Changer de parcours",
        path: "/patient/medicalStays",
      },
      {
        icon: Gear,
        text: "Paramètres",
        path: "/",
      },
    ],
    [
      {
        icon: Question,
        text: "Aide",
        path: "/",
      },
      {
        icon: SignOut,
        text: "Déconnexion",
        onClick: () => {
          msal.instance.logoutRedirect();
        },
        noCaret: true,
      },
    ],
  ];

  let activeAccount = msal.instance.getActiveAccount();
  if (!activeAccount) {
    msal.instance.setActiveAccount(msal.instance.getAllAccounts()[0]);
    activeAccount = msal.instance.getActiveAccount();
  }

  const { family_name: lastName, given_name: firstName } =
    activeAccount?.idTokenClaims;

  return (
    <div className="relative">
      <motion.button
        className="px-2 py-1 md:px-4 md:py-3 flex flex-row items-center gap-x-1 md:gap-x-4 bg-[#F4F5F6] rounded-2xl"
        whileTap={{ scale: 0.95 }}
        onClick={() => {
          setShowMenu(!showMenu);
          controls.start({
            rotate: showMenu ? 0 : 45,
            transition: { duration: 0.5 },
          });
        }}
      >
        <div className="h-8 w-8 md:h-10 md:w-10 flex aspect-square object-cover rounded-full overflow-hidden">
          {user?.avatar !== undefined && (
            <img src={`/avatars/${user.avatar}`} alt="avatar" />
          )}
        </div>
        <div className="hidden md:flex flex-1 flex-col text-left">
          <div className="font-[Poppins-SemiBold]">
            {firstName} {lastName}
          </div>
          <div className="text-[#707889] text-xs">Patient</div>
        </div>
        <div className={classNames({ "rotate-180": showMenu })}>
          <CaretDown weight="bold" size={24} />
        </div>
      </motion.button>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="absolute top-12 right-0 mt-2 bg-white border border-gray-200 rounded shadow-xl 
                w-64 px-6 py-6 flex flex-col justify-center space-y-5"
          >
            <div className="flex items-center space-x-2">
              <div className="h-14 w-14 rounded-full overflow-hidden">
                <img src={`/avatars/${user.avatar}`} alt="avatar" />
              </div>
              <div className="flex flex-col items-start justify-center leading-5">
                <p className="font-[Poppins-SemiBold] ">
                  {firstName} {lastName}
                </p>
                <p className="text-gray-500 font-thin">Patient</p>
              </div>
            </div>
            {menuUser?.map((sections, index) => (
              <div
                key={`Section ${index}`}
                className="flex flex-col space-y-5 w-full"
              >
                <hr />
                <div className="flex flex-col justify-center space-y-3">
                  {sections?.map((line) => {
                    return (
                      <div
                        className="flex justify-between items-center space-x-2 group hover:cursor-pointer"
                        onClick={() => {
                          setShowMenu(false);
                          if (line.onClick) {
                            line.onClick();
                          } else {
                            navigate(line.path);
                          }
                        }}
                      >
                        <div className="flex items-center space-x-2">
                          <line.icon size={20} />
                          <p className="font-[Poppins-SemiBold] text-sm group-hover:underline">
                            {line.text}
                          </p>
                        </div>
                        {!line.noCaret && (
                          <CaretRight size={14} weight="bold" />
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default UserPanelButton;
