import React, { useEffect, useState } from "react";

import { Spinner } from "phosphor-react";

import PdfHelper from "~/helpers/PdfHelper";

const imageTypes = ["image/png", "image/jpg", "image/jpeg", "image/gif"];

function FilePreview({ file }) {
  const [fileContent, setFileContent] = useState(null);

  const readImage = (file) => {
    const reader = new FileReader();

    reader.onload = function (e) {
      let dataURL = e.target.result;
      dataURL = dataURL.replace(";base64", `;name=${file.name};base64`);
      setFileContent(dataURL);
    };

    reader.readAsDataURL(file);
  };

  const readPDF = async (file) => {
    const uri = URL.createObjectURL(file);
    const img = await PdfHelper.toBase64Image(uri);
    setFileContent(img);
  };

  useEffect(() => {
    if (!file) {
      setFileContent(null);
      return;
    }

    if (imageTypes.includes(file.type)) {
      readImage(file);
    } else if (file.type === "application/pdf") {
      readPDF(file);
    }
  }, [file]);

  if (!file) return null;

  return fileContent ? (
    <img
      src={fileContent}
      alt="preview"
      className="object-contain max-h-[80px]"
    />
  ) : (
    <Spinner weight="bold" />
  );
}

export default FilePreview;
