import Bed from "~/images/roomOptions/bed.svg";
import Breakfast from "~/images/roomOptions/breakfast.svg";
import Safe from "~/images/roomOptions/safe.svg";
import Tel from "~/images/roomOptions/tel.svg";
import Tv from "~/images/roomOptions/tv.svg";
import Wifi from "~/images/roomOptions/wifi.svg";

const roomOptions = [
  {
    id: 1,
    name: "TV",
    description: "TNT + bouquet Canal",
    price: 3,
    icon: Tv,
  },
  {
    id: 2,
    name: "Grand écran",
    description: '4k Oled 55"',
    price: 5,
    icon: Tv,
  },
  {
    id: 3,
    name: "Téléphone",
    description: "Appels illimités vers fixe + mobile",
    price: 2,
    icon: Tel,
  },
  {
    id: 4,
    name: "Wifi",
    description: "Accès illimité",
    price: 2,
    icon: Wifi,
  },
  {
    id: 5,
    name: "Coffre fort",
    description: "Coffre numérique sans clé",
    price: 6,
    icon: Safe,
  },
  {
    id: 6,
    name: "Journaux",
    description: "Quotidiens nationaux et régionaux",
    price: 2,
    icon: Breakfast,
  },
  {
    id: 7,
    name: "Tassimo avec dosettes",
    description: "Thé, café, chocolat chaud",
    details: true,
    price: 4,
    icon: Breakfast,
  },
  {
    id: 8,
    name: "Petit déjeuner gourmand",
    description: "Servis au quotidien",
    details: true,
    price: 6,
    icon: Breakfast,
  },
  {
    id: 9,
    name: "Linge de toilette",
    description: "Peignoir et serviette de bain",
    details: true,
    price: 3,
    icon: Bed,
  },
  {
    id: 10,
    name: "Trousse de toilette complète",
    description: "Avec une brosse à dent neuve",
    details: true,
    price: 4,
    icon: Bed,
  },
  {
    id: 11,
    name: "Chambre spacieuse",
    description: "Salon d'accueil",
    price: 18,
    icon: Bed,
  },
  {
    id: 12,
    name: "Chambre privative",
    description: "Calme et intime",
    details: true,
    price: 8,
    icon: Bed,
  },
  {
    id: 13,
    name: "Prioritaire sur la chambre",
    description: "Uniquements sur chambre particulière",
    price: 8,
    icon: Bed,
  },
  {
    id: 15,
    name: "Offre repas gourmet",
    description: "1 repas gourmet pendant le séjour",
    details: true,
    price: 25,
    icon: Breakfast,
  },
];

export default roomOptions;
