import React, { useEffect } from "react";

import _ from "lodash";
import moment from "moment";
import { CaretLeft, CaretRight, Check } from "phosphor-react";
import { useSelector } from "react-redux";

import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import FormGroup from "~/components/forms/formGroup/FormGroup";
import Label from "~/components/forms/label/Label";
import ValidationForm from "~/components/forms/validationForm/ValidationForm";
import ValidationInput from "~/components/forms/validationInput/ValidationInput";
import ValidationPhoneNumberInput from "~/components/forms/validationPhoneNumberInput/ValidationPhoneNumberInput";
import ValidationRadioButtonsGroup from "~/components/forms/validationRadioButtonsGroup/ValidationRadioButtonsGroup";
import ValidationSelect from "~/components/forms/validationSelect/ValidationSelect";

import Panel from "~/components/panel/Panel";
import usePread from "~/contexts/usePread";
import AccompagnantImg from "~/images/accompagnant.svg";
import CompanionImg from "~/images/Companion.svg";
import FormAcc from "~/images/illustrations/formAcc.png";
import StickyFooter from "~/pages/preadmission/components/stickyFooter";
import { useSaveCompanionStep } from "~/queries/preadQueries";

export const companionStepPageParameters = {
  title: "Vos informations administratives",
  subTitle: null,
  image: FormAcc,
  imageTitle: "Entourage",
};

function CompanionStep({ onSubmitted, onPrevious }) {
  const { config, medicalStay, saveCompanionsData } = usePread();
  const { entourage } = useSelector((state) => state.user);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useSaveCompanionStep((data, variables, context) => {
    console.log("saved");
    saveCompanionsData(variables);
    onSubmitted();
  });

  const onTrustedPersonEntourageChanged = (value, formik) => {
    const selected = _.find(entourage, (e) => e.id === value);

    const { setFieldValue } = formik;
    setFieldValue(
      "trustedPersonRelationshipType",
      selected?.relationshipType?.value
    );
    setFieldValue("trustedPersonLastName", selected?.lastName);
    setFieldValue("trustedPersonFirstName", selected?.firstName);
    setFieldValue("trustedPersonEmail", selected?.email);
    setFieldValue("trustedPersonMobilePhone", selected?.mobilePhone);
  };

  const onPersonToBeNotifiedEntourageChanged = (value, formik) => {
    const selected = _.find(entourage, (e) => e.id === value);

    const { setFieldValue } = formik;
    setFieldValue(
      "personToBeNotifiedRelationshipType",
      selected?.relationshipType?.value
    );
    setFieldValue("personToBeNotifiedLastName", selected?.lastName);
    setFieldValue("personToBeNotifiedFirstName", selected?.firstName);
    setFieldValue("personToBeNotifiedEmail", selected?.email);
    setFieldValue("personToBeNotifiedMobilePhone", selected?.mobilePhone);
  };

  return medicalStay && config ? (
    <>
      <Container className="flex flex-col w-full items-center justify-center mb-32">
        <ValidationForm
          id="companionForm"
          initialValues={{}}
          onSubmit={(formData) => {
            console.log("SOUBMITE");
            handleSubmit.mutate(formData);
          }}
        >
          {(formik) => (
            <div className="w-full">
              <div className="flex flex-col space-y-16 justify-center">
                <Panel
                  collapsible
                  title="Personne de confiance"
                  headerContent={
                    <>
                      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 w-full md:pr-20">
                        <div className="flex items-center justify-center space-x-4 px-4 py-2 bg-white border border-gray-400 rounded-2xl">
                          <img
                            src={CompanionImg}
                            alt="companion"
                            className="w-8 shrink-0"
                          />
                          <div className="flex flex-col">
                            <p className="font-[Poppins-SemiBold]">
                              Pour vous accompagner
                            </p>
                            <p className="text-sm leading-4">
                              Dans vos démarches et vous assister lors de vos
                              rendez-vous médicaux.
                            </p>
                          </div>
                        </div>
                        <div className="flex items-center justify-center space-x-4 px-4 py-2 bg-white border border-gray-400 rounded-2xl">
                          <img
                            src={AccompagnantImg}
                            alt="companion"
                            className="w-8 shrink-0"
                          />
                          <div className="flex flex-col">
                            <p className="font-[Poppins-SemiBold]">
                              Pour être votre référent
                            </p>
                            <p className="text-sm leading-4">
                              Rendre compte de vos volontés si vous n’êtes pas
                              en mesure d’être consulté.
                            </p>
                          </div>
                        </div>
                      </div>
                      <p className="mt-5">
                        Son avis guide le médecin pour prendre des décisions.
                        Elle doit donc connaître vos volontés et les exprimer
                        lorsqu'elle est appelée à le faire.{" "}
                        <span className="underline">
                          Les directives anticipées
                        </span>{" "}
                        peuvent également lui être confiées.
                      </p>
                    </>
                  }
                >
                  <FormGroup>
                    <Label htmlFor="trustedPersonId" className="font-bold">
                      Vous aviez précédemment enregistré des contacts de votre
                      entourage sur votre profil. <br />
                      Sélectionnez la personne de confiance dans la liste
                      ci-dessous :
                    </Label>

                    <ValidationSelect
                      id="trustedPersonId"
                      name="trustedPersonId"
                      options={[
                        ...entourage?.map((companion) => ({
                          value: companion?.id,
                          label:
                            companion?.firstName +
                            " " +
                            companion?.lastName +
                            " - Ajouté(e) le " +
                            moment(companion?.addedDate).format("DD/MM/YYYY"),
                        })),
                        { value: 0, label: "Une autre personne" },
                      ]}
                      onChange={(value) =>
                        onTrustedPersonEntourageChanged(value, formik)
                      }
                      placeholder="Sélectionnez un contact pré-enregistré..."
                    />
                  </FormGroup>

                  <FormGroup className="mt-8">
                    <Label htmlFor="trustedPersonRelationshipType">
                      Votre personne de confiance est
                    </Label>
                    <ValidationSelect
                      name="trustedPersonRelationshipType"
                      options={config.relationshipTypes}
                    />
                  </FormGroup>

                  <div className="mt-7 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-7">
                    <FormGroup>
                      <Label htmlFor="trustedPersonLastName">
                        Nom de naissance
                      </Label>
                      <ValidationInput
                        id="trustedPersonLastName"
                        name="trustedPersonLastName"
                        placeholder="Nom de naissance"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="trustedPersonFirstName">Prénom</Label>
                      <ValidationInput
                        id="trustedPersonFirstName"
                        name="trustedPersonFirstName"
                        placeholder="Prénom"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="trustedPersonEmail">Adresse email</Label>
                      <ValidationInput
                        id="trustedPersonEmail"
                        name="trustedPersonEmail"
                        placeholder="Adresse email"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="trustedPersonMobilePhone">
                        Téléphone
                      </Label>
                      <ValidationPhoneNumberInput
                        id="trustedPersonMobilePhone"
                        name="trustedPersonMobilePhone"
                        placeholder="XXXXXXXX"
                      />
                    </FormGroup>
                  </div>

                  <Label
                    htmlFor="trustedPersonKnowsAdvanceDirectives"
                    className="mt-9 block"
                  >
                    Lui avez-vous fait part de vos directives anticipées ?
                  </Label>

                  <div className="text-sm font-[DMSans-Regular] mt-3 mb-6">
                    Toute personne majeure peut, si elle le souhaite, faire une
                    déclaration écrite appelée directives anticipées pour
                    préciser ses souhaits concernant sa fin de vie. Ce document
                    aidera les médecins, le moment venu, à prendre leurs
                    décisions sur les soins à donner, si la personne ne peut
                    plus exprimer ses volontés.
                  </div>

                  <ValidationRadioButtonsGroup
                    id="trustedPersonKnowsAdvanceDirectives"
                    name="trustedPersonKnowsAdvanceDirectives"
                    values={[
                      { value: "0", label: "Oui" },
                      { value: "1", label: "Non" },
                    ]}
                  />

                  <Label
                    htmlFor="trustedPersonKnowsDirectives"
                    className="mt-6 mb-7 block"
                  >
                    Cette personne possède-t-elle un exemplaire de vos
                    directives ?
                  </Label>
                  <ValidationRadioButtonsGroup
                    id="trustedPersonKnowsDirectives"
                    name="trustedPersonKnowsDirectives"
                    values={[
                      { value: "0", label: "Oui" },
                      { value: "1", label: "Non" },
                    ]}
                  />
                  <Button
                    block
                    className="mt-9"
                    startIcon={<Check size={22} weight="bold" />}
                  >
                    Valider
                  </Button>
                </Panel>

                <Panel
                  title="Personne à prévenir"
                  subtitle="C’est la personne à alerter en cas d'aggravation de votre état de santé. Les informations communiquées à cette personne sont limitées et soumises aux restrictions liées au secret médical"
                >
                  <FormGroup>
                    <Label htmlFor="personToBeNotifiedId" className="font-bold">
                      Vous aviez précédemment enregistré des contacts de votre
                      entourage sur votre profil. <br />
                      Sélectionnez la personne à prévenir dans la liste
                      ci-dessous :
                    </Label>

                    <ValidationSelect
                      id="personToBeNotifiedId"
                      name="personToBeNotifiedId"
                      options={[
                        ...entourage?.map((companion) => ({
                          value: companion?.id,
                          label:
                            companion?.firstName +
                            " " +
                            companion?.lastName +
                            " - Ajouté(e) le " +
                            moment(companion?.addedDate).format("DD/MM/YYYY"),
                        })),
                        { value: 0, label: "Une autre personne" },
                      ]}
                      onChange={(value) =>
                        onPersonToBeNotifiedEntourageChanged(value, formik)
                      }
                      placeholder="Sélectionnez un contact pré-enregistré..."
                    />
                  </FormGroup>

                  <FormGroup className="mt-8">
                    <Label htmlFor="personToBeNotifiedRelationshipType">
                      Votre personne à prévenir est :
                    </Label>
                    <ValidationSelect
                      name="personToBeNotifiedRelationshipType"
                      options={config.relationshipTypes}
                    />
                  </FormGroup>

                  <div className="mt-7 grid grid-cols-1 md:grid-cols-2 gap-x-6 gap-y-7">
                    <FormGroup>
                      <Label htmlFor="personToBeNotifiedLastName">
                        Nom de naissance
                      </Label>
                      <ValidationInput
                        id="personToBeNotifiedLastName"
                        name="personToBeNotifiedLastName"
                        placeholder="Nom de naissance"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="personToBeNotifiedFirstName">
                        Prénom
                      </Label>
                      <ValidationInput
                        id="personToBeNotifiedFirstName"
                        name="personToBeNotifiedFirstName"
                        placeholder="Prénom"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="personToBeNotifiedEmail">
                        Adresse email
                      </Label>
                      <ValidationInput
                        id="personToBeNotifiedEmail"
                        name="personToBeNotifiedEmail"
                        placeholder="Adresse email"
                      />
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="personToBeNotifiedMobilePhone">
                        Téléphone
                      </Label>
                      <ValidationPhoneNumberInput
                        id="personToBeNotifiedMobilePhone"
                        name="personToBeNotifiedMobilePhone"
                        placeholder="XXXXXXXX"
                      />
                    </FormGroup>
                  </div>

                  <Button
                    block
                    className="mt-9"
                    startIcon={<Check size={22} weight="bold" />}
                  >
                    Valider
                  </Button>
                </Panel>
              </div>
            </div>
          )}
        </ValidationForm>
      </Container>
      <StickyFooter>
        <div className="flex flex-row items-center justify-between space-x-10 w-full lg:px-20">
          <Button
            size="s"
            color="white"
            className="flex items-center font-[Poppins-SemiBold] border space-x-2 px-12"
            startIcon={<CaretLeft size={16} />}
            onClick={onPrevious}
          >
            <p>Retour</p>
          </Button>

          <div className="gap-x-6 flex flex-row items-center">
            <div className="font-[Poppins-SemiBold] text-right hidden xl:text-xs">
              En validant, vous allez recevoir un email pour signer
              électroniquement la désignation de votre personne de confiance et
              de votre personne à prévenir
            </div>
            <Button
              size="s"
              type="submit"
              form="companionForm"
              endIcon={<CaretRight size={16} />}
              className="flex-shrink-0"
            >
              Valider
            </Button>
          </div>
        </div>
      </StickyFooter>
    </>
  ) : null;
}

export default CompanionStep;
