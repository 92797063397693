import React from "react";

import { Outlet } from "react-router-dom";

import Logo from "~/images/logo.svg";
import welcomeImg from "~/images/illustrations/doctors.png";

function LeftColumnLayout() {
  return (
    <div className="flex items-center justify-center h-screen w-full ">
      <div className="grid grid-cols-1 md:grid-cols-2 md:border max-w-screen-2xl overflow-hidden md:rounded-xl md:shadow-2xl">
        <div className="py-36 pl-8 md:pl-20 pr-6 md:pr-12">
          <img src={Logo} alt="Elsan Care" className="cursor-pointer" />
          <Outlet />
        </div>
        <div
          className="hidden md:flex justify-items-center h-full w-full p-8
        bg-gradient-to-bl from-[#F9B004] to-[#FBCC5C]"
        >
          <img src={welcomeImg} alt="welcome" className="object-scale-down" />
        </div>
      </div>
    </div>
  );
}

export default LeftColumnLayout;
