import { useQuery } from "@tanstack/react-query";

import news from "~/dataSamples/News";

export const useGetNews = () =>
  useQuery({
    queryKey: ["news"],
    queryFn: () => Promise.resolve(news),
    staleTime: 3600 * 24 * 1000, // 1 day
  });
