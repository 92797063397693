import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

function RadioButton({
  id,
  name,
  value,
  checked,
  className,
  onCheckedChange,
  label,
  ...fieldValidationProps
}) {
  return (
    <label className="flex flex-row items-center gap-x-4">
      <input
        id={id}
        name={name}
        className="hidden"
        type="radio"
        checked={checked}
        value={value}
        {...fieldValidationProps}
        onChange={onCheckedChange}
      />
      <div
        className={classNames(
          "h-8 w-8 border border-neutral-700 bg-white aspect-square p-1.5 items-center justify-center overflow-hidden rounded-full",
          className
        )}
      >
        {checked && (
          <div className="bg-yellow-500 w-full h-full rounded-full" />
        )}
      </div>
      <div>{label}</div>
    </label>
  );
}

RadioButton.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  value: PropTypes.string,
  checked: PropTypes.bool,
  className: PropTypes.string,
  onCheckedChange: PropTypes.func,
};

RadioButton.defaultProps = {
  className: "",
};

export default RadioButton;
