import React from "react";

import Tippy from "@tippyjs/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function IconButton({
  children,
  href,
  target,
  onClick,
  className,
  shadow,
  tooltip,
}) {
  const ContainerClass = tooltip ? Tippy : React.Fragment;
  const containerProps = tooltip
    ? { content: tooltip, placement: "top" }
    : null;

  let Comp = null;
  let props = {};

  if (href) {
    if (href.startsWith("http")) {
      // it's an external link, we can't use react-router
      Comp = "a";
      props.href = href;
    } else {
      Comp = Link;
      props.to = href;
    }

    props.target = target;
  } else {
    Comp = "button";
    props.onClick = onClick;
    props.type = "button";
  }

  return (
    <ContainerClass {...containerProps}>
      <Comp
        {...props}
        className={classNames(
          "flex items-center justify-center w-10 h-10 rounded-xl border-2 border-[#FFC01B] bg-white focus-visible:outline-none text-xl font-normal text-center aspect-square ",
          {
            shadow: shadow,
          },
          className
        )}
      >
        {children}
      </Comp>
    </ContainerClass>
  );
}

IconButton.propTypes = {
  icon: PropTypes.node,
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  onClick: PropTypes.func,
  className: PropTypes.string,
  shadow: PropTypes.bool,
  tooltip: PropTypes.node,
};

IconButton.defaultProps = {
  shadow: false,
};

export default IconButton;
