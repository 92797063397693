import React from "react";

function FieldCard({ title, className, children }) {
  return (
    <div className={`flex flex-col justify-start space-y-1 ${className}`}>
      <p className="font-[Poppins-SemiBold]">{title}</p>

      <div>{children}</div>
    </div>
  );
}

export default FieldCard;
