import React from "react";

function InfoCard({ title, tag, className, children }) {
  return (
    <div className="flex flex-col w-full rounded-xl  border border-gray-100 overflow-hidden h-full ">
      <div className="flex items-center space-x-3 p-4">
        <p className="text-lg font-[Poppins-SemiBold]">{title}</p>
        {tag && (
          <p className="rounded-full bg-gray-100 px-3 text-sm font-[Poppins-SemiBold]">
            {tag}
          </p>
        )}
      </div>
      <div className="border-b border-gray-100" />
      <div
        className={`grid grid-cols-1 md:grid-cols-2 gap-x-2 gap-y-4 p-4 text-sm col ${className}`}
      >
        {children}
      </div>
    </div>
  );
}

export default InfoCard;
