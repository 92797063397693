import { useMutation, useQuery } from "@tanstack/react-query";
import _ from "lodash";

import medicalStays from "~/dataSamples/MedicalStays";
import preadConfig from "~/dataSamples/PreadConfig";

export const findMedicalStay = (serialNumber) => ({
  queryKey: ["preadMedicalStay", serialNumber],
  queryFn: () =>
    Promise.resolve(
      _.find(medicalStays, (m) => m.serialNumber === serialNumber)
    ),
});

export const getConfig = (serialNumber) => ({
  queryKey: ["preadConfig", serialNumber],
  queryFn: () => Promise.resolve(preadConfig),
  staleTime: 3600 * 1000,
  retry: 2,
});

export const useLoadMedicalStay = (serialNumber) =>
  useQuery(findMedicalStay(serialNumber));

export const useLoadConfig = (serialNumber) =>
  useQuery(getConfig(serialNumber));

export const useSavePatientStep = (onSuccess) =>
  useMutation({
    mutationFn: (formValues) =>
      new Promise((resolve) => {
        console.log("api pread - save patient");
        setTimeout(() => resolve(), 300);
      }),
    onSuccess,
  });

export const useSaveCompanionStep = (onSuccess) =>
  useMutation({
    mutationFn: (formValues) =>
      new Promise((resolve) => {
        console.log("api pread - save companions");
        setTimeout(() => resolve(), 300);
      }),
    onSuccess,
  });
