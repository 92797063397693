import React from "react";

function PageHeader({ image, imageTitle = "", title = "", children }) {
  return (
    <div
      className={`flex flex-col w-full space-y-3  ${
        children ? "md:my-10 my-6" : "md:my-14 my-10"
      }`}
    >
      <p className="xl:text-4xl lg:text-3xl text-2xl font-[Poppins-Bold] ">
        {title}
      </p>
      {children && (
        <p className="text-xs lg:text-sm font-[Poppins-SemiBold] ">
          {children}
        </p>
      )}
    </div>
  );
}

export default PageHeader;
