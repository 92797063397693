const RoomPhotos = [
  {
    id: 1,
    url: "https://cdn-assets-eu.frontify.com/s3/frontify-enterprise-files-eu/eyJvYXV0aCI6eyJjbGllbnRfaWQiOiJjbGllbnQtZGszOHhmd2NldmZ5cXJ2biJ9LCJwYXRoIjoiaWhoLWhlYWx0aGNhcmUtYmVyaGFkXC9hY2NvdW50c1wvYzNcLzQwMDA2MjRcL3Byb2plY3RzXC8yMDlcL2Fzc2V0c1wvMzdcLzM0Njk0XC81NWIxOTlhNjU3MDZkY2VmZGMxN2E4NzM2ZDYyNWEwMi0xNjUyMTcxNzA1LmpwZyJ9:ihh-healthcare-berhad:tluKn9t-UNdKEuWhwI9zBuW19w99lF3SJKM6qf0daOs?format=webp",
    alt: "bedroom",
    title: "Chambre",
  },
  {
    id: 2,
    url: "http://www.codehostels.com/wp-content/uploads/2019/04/IMG_6945.jpg",
    alt: "breakfast",
    title: "Petit dejeuner",
  },
  {
    id: 3,
    url: "https://m.media-amazon.com/images/I/61FnpnH2MAL.jpg",
    alt: "telephone",
    title: "Téléphone",
  },
  {
    id: 4,
    url: "https://www.ariase.com/uploads/media/663d89d7f38f24bd105cfa64e8fd5976775af9f9.jpg",
    alt: "tv",
    title: "Television",
  },
  {
    id: 5,
    url: "https://www.netspotapp.com/images/upload/images/Wi_Fi@2x.jpg",
    alt: "wifi",
    title: "Wifi",
  },
  {
    id: 6,
    url: "https://thumbs.dreamstime.com/b/food-delivered-to-patient-hospital-bed-focus-meal-164421510.jpg",
    alt: "gourmet",
    title: "Repas gourmet",
  },
];

export default RoomPhotos;
