import moment from "moment";

const medicalStays = [
  {
    id: 1,
    serialNumber: "6c1cc2bd-d405-4c1a-907f-e3857735535e",
    patientId: 1,
    clinicId: "SSA",
    location: "Polyclinique du Parc",
    address: "48 Rue Henri Barbusse, 59880 Saint-Saulve",
    tel: "03 59 75 07 51",
    interventionId: 1,
    intervention: "orthopédique",
    preadAvailable: true,
    preadEngaged: true,
    startDate: moment().add(7, "days").format("YYYY-MM-DD [12:00:00.000]"),
    endDate: moment().add(7, "days").format("YYYY-MM-DD [12:00:00.000]"),
    practitioner: "Dr S. Moore",
    patient: {
      firstName: "Kevin",
      lastName: "Perriat",
      birthName: "Perriat",
      birthDate: "1989-08-03",
      email: "kevin.perriat@elsan.care",
      mobilePhone: "0698643375",
      sex: "1",
      address: "5 rue Gaston Marchou",
      postCode: "33300",
      city: "Bordeaux",
    },
    pread: {
      patient: {
        firstName: "Kevin",
        lastName: "Perriat",
        birthName: "Perriat",
        birthDate: "1989-08-03",
        email: "kevin.perriat@elsan.care",
        mobilePhone: "0698643375",
        sex: "1",
        address: "5 rue Gaston Marchou",
        postCode: "33300",
        city: "Bordeaux",
        clinic: "Polyclinique du Parc",
      },
      trustedPerson: {
        lastName: "Perriat",
        firstName: "Laurence",
        email: "lperriat@gmail.com",
        mobilePhone: "0606060606",
        relationshipType: "child",
      },
      steps: {
        patientInfo: false,
        companions: false,
      },
    },
  },
  {
    id: 1,
    serialNumber: "ba9506a0-54cd-4607-b1fe-409d0e3597cb",
    patientId: 1,
    clinicId: "SSA",
    location: "Polyclinique du Parc",
    address: "48 Rue Henri Barbusse, 59880 Saint-Saulve",
    tel: "03 59 75 07 51",
    interventionId: 2,
    intervention: "maternité",
    preadAvailable: true,
    preadEngaged: false,
    startDate: moment().add(30, "days").format("YYYY-MM-DD [12:00:00.000]"),
    endDate: moment().add(30, "days").format("YYYY-MM-DD [12:00:00.000]"),
    practitioner: "Dr S. Moore",
    patient: {
      firstName: "Kevin",
      lastName: "Perriat",
      birthName: "Perriat",
      birthDate: "1989-08-03",
      email: "kevin.perriat@elsan.care",
      mobilePhone: "0698643375",
      sex: "1",
      address: "5 rue Gaston Marchou",
      postCode: "33300",
      city: "Bordeaux",
    },
    pread: {
      patient: {
        firstName: "Kevin",
        lastName: "Perriat",
        birthName: "Perriat",
        birthDate: "1989-08-03",
        email: "kevin.perriat@elsan.care",
        mobilePhone: "0698643375",
        sex: "1",
        address: "5 rue Gaston Marchou",
        postCode: "33300",
        city: "Bordeaux",
        clinic: "Polyclinique du Parc",
      },
      trustedPerson: {
        lastName: "Perriat",
        firstName: "Laurence",
        email: "lperriat@gmail.com",
        mobilePhone: "0606060606",
        relationshipType: "child",
      },
      steps: {
        patientInfo: false,
        companions: false,
      },
    },
  },
  {
    id: 2,
    serialNumber: "cbf7dc12-2fc7-4960-a54e-264a99976c33",
    patientId: 1,
    clinicId: "SSA",
    location: "Polyclinique du Parc",
    address: "48 Rue Henri Barbusse, 59880 Saint-Saulve",
    tel: "03 59 75 07 51",
    interventionId: 1,
    intervention: "orthopédique",
    preadAvailable: false,
    preadEngaged: false,
    startDate: moment()
      .subtract(30, "days")
      .format("YYYY-MM-DD [12:00:00.000]"),
    endDate: moment().subtract(30, "days").format("YYYY-MM-DD [12:00:00.000]"),
    practitioner: "Dr S. Moore",
    patient: {
      firstName: "Kevin",
      lastName: "Perriat",
      birthName: "Perriat",
      birthDate: "1989-08-03",
      email: "kevin.perriat@elsan.care",
      mobilePhone: "0698643375",
      sex: "1",
      address: "5 rue Gaston Marchou",
      postCode: "33300",
      city: "Bordeaux",
    },
    pread: {
      patient: {
        firstName: "Kevin",
        lastName: "Perriat",
        birthName: "Perriat",
        birthDate: "1989-08-03",
        email: "kevin.perriat@elsan.care",
        mobilePhone: "0698643375",
        sex: "1",
        address: "5 rue Gaston Marchou",
        postCode: "33300",
        city: "Bordeaux",
        clinic: "Polyclinique du Parc",
      },
      trustedPerson: {
        lastName: "Perriat",
        firstName: "Laurence",
        email: "lperriat@gmail.com",
        mobilePhone: "0606060606",
        relationshipType: "child",
      },
      steps: {
        patientInfo: false,
        companions: false,
      },
    },
  },
  {
    id: 3,
    serialNumber: "1eca300d-42f8-43a6-bd60-2baa9123d482",
    patientId: 1,
    clinicId: "SSA",
    location: "Polyclinique du Parc",
    address: "48 Rue Henri Barbusse, 59880 Saint-Saulve",
    tel: "03 59 75 07 51",
    interventionId: 3,
    intervention: "bariatrique",
    preadAvailable: false,
    preadEngaged: false,
    startDate: moment()
      .subtract(35, "days")
      .format("YYYY-MM-DD [12:00:00.000]"),
    endDate: moment().subtract(35, "days").format("YYYY-MM-DD [12:00:00.000]"),
    practitioner: "Dr S. Moore",
    patient: {
      firstName: "Kevin",
      lastName: "Perriat",
      birthName: "Perriat",
      birthDate: "1989-08-03",
      email: "kevin.perriat@elsan.care",
      mobilePhone: "0698643375",
      sex: "1",
      address: "5 rue Gaston Marchou",
      postCode: "33300",
      city: "Bordeaux",
    },
    pread: {
      patient: {
        firstName: "Kevin",
        lastName: "Perriat",
        birthName: "Perriat",
        birthDate: "1989-08-03",
        email: "kevin.perriat@elsan.care",
        mobilePhone: "0698643375",
        sex: "1",
        address: "5 rue Gaston Marchou",
        postCode: "33300",
        city: "Bordeaux",
        clinic: "Polyclinique du Parc",
      },
      trustedPerson: {
        lastName: "Perriat",
        firstName: "Laurence",
        email: "lperriat@gmail.com",
        mobilePhone: "0606060606",
        relationshipType: "child",
      },
      steps: {
        patientInfo: false,
        companions: false,
      },
    },
  },
];

export default medicalStays;
