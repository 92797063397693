import React from "react";

import classNames from "classnames";

function RadioButtonsGroup({
  id,
  name,
  value,
  values,
  disabled,
  onChange,
  ...fieldValidationProps
}) {
  return (
    <div className="flex flex-row w-fit h-16 border border-neutral-500 rounded-xl overflow-hidden">
      {values?.map((button, index) => {
        const checked = value ? button.value === value : undefined;

        return (
          <label key={index}>
            <input
              type="radio"
              name={name}
              className="hidden peer"
              {...fieldValidationProps}
              value={button.value}
              checked={checked}
              onChange={onChange}
              disabled={disabled ? "disabled" : undefined}
            />
            <div
              className={classNames(
                "px-4 py-5 min-w-[150px] text-center peer-checked:bg-yellow-500 peer-[:not(:checked)]:bg-white",
                {
                  "cursor-pointer peer-[:not(:checked)]:peer-hover:bg-yellow-400":
                    !disabled,
                }
              )}
            >
              {button.label}
            </div>
          </label>
        );
      })}
    </div>
  );
}

export default RadioButtonsGroup;
