import React from "react";

import { Bell } from "phosphor-react";
import { Outlet, useNavigate } from "react-router-dom";

import PageFooter from "~/components/pageFooter/PageFooter";
import Logo from "~/images/logo.svg";

import UserPanelButton from "./components/UserPanelButton";

function StickerHeader() {
  const navigate = useNavigate();
  return (
    <div
      className="fixed w-full top-0 left-0 bg-white h-20 
             flex flex-row items-center justify-between px-8 text-[16px]
             transform:translate-x-1/2 
             shadow-[0_6px_6px_-4px_rgba(0,0,0,0.15)] z-40"
    >
      <div className="flex flex-row flex-1 items-center justify-between relative">
        <div className="flex flex-row items-center space-x-6 ">
          <img
            src={Logo}
            alt="Elsan Care"
            className="cursor-pointer"
            onClick={() => navigate("/dashboard")}
          />
        </div>
        <div className="flex flex-row items-center space-x-3 ">
          <div className="relative">
            <Bell size={24} weight="bold" />
            <div className="absolute -top-1 -right-1 rounded-full bg-red-500 text-white text-[10px] w-4 h-4 flex items-center justify-center">
              3
            </div>
          </div>
          <UserPanelButton />
        </div>
      </div>
    </div>
  );
}

function PageLayout() {
  return (
    <div className="relative flex justify-center items-start h-full w-full min-h-screen bg-[#F5F6FD]">
      <div className="relative flex flex-col items-center w-full h-full z-10">
        <Outlet />
        <PageFooter />
      </div>
      <StickerHeader />
    </div>
  );
}

export default PageLayout;
