import React from "react";

import { Field } from "formik";

import DatePickerBase from "~/components/forms_unvalidated/datePicker/DatePicker";

function ValidationDatePicker({
  id,
  name,
  min,
  max,
  className,
  noErrorMessage,
  disabled,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form: { setFieldValue }, meta }) => (
          <DatePickerBase
            {...field}
            id={id}
            name={name}
            min={min}
            max={max}
            className={className}
            value={meta.value}
            touched={meta.touched}
            error={meta.error}
            noErrorMessage={noErrorMessage}
            disabled={disabled}
            onChange={(val) => {
              setFieldValue(name, val);
            }}
          />
        )}
      </Field>
    </>
  );
}

ValidationDatePicker.propTypes = {
  id: DatePickerBase.propTypes.id,
  name: DatePickerBase.propTypes.name,
  className: DatePickerBase.propTypes.className,
  onChange: DatePickerBase.propTypes.onChange,
  noErrorMessage: DatePickerBase.propTypes.noErrorMessage,
  disabled: DatePickerBase.propTypes.disabled,
};

ValidationDatePicker.defaultProps = {
  className: DatePickerBase.defaultProps.className,
  noErrorMessage: DatePickerBase.defaultProps.noErrorMessage,
  disabled: DatePickerBase.defaultProps.disabled,
};

export default ValidationDatePicker;
