import React from "react";

import moment from "moment";
import { useParams } from "react-router-dom";

import PageDataLoader from "~/components/pageDataLoader/PageDataLoader";
import { useLoadMedicalStay } from "~/queries/medicalStaysQueries";

import MedicalStayPostOp from "./components/MedicalStayPostOp";
import MedicalStayPreOp from "./components/MedicalStayPreOp";

function MedicalStayPage() {
  const { serialNumber } = useParams();
  const medicalStayQuery = useLoadMedicalStay(serialNumber);

  const medicalStay = medicalStayQuery.data;

  const dayBeforeMedicalStay = moment(medicalStay?.startDate)?.diff(
    moment(),
    "days"
  );

  return (
    <PageDataLoader isLoading={medicalStayQuery?.isLoading}>
      {dayBeforeMedicalStay > 0 ? (
        <MedicalStayPreOp medicalStay={medicalStay} />
      ) : (
        <MedicalStayPostOp medicalStay={medicalStay} />
      )}
    </PageDataLoader>
  );
}

export default MedicalStayPage;
