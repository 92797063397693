import React from "react";

import classNames from "classnames";
import { CaretLeft } from "phosphor-react";

import IconButton from "~/components/iconButton/IconButton";

function CarouselArrow({ right, onClick }) {
  return (
    <IconButton
      onClick={onClick}
      className={classNames("absolute", {
        "left-4": !right,
        "rotate-180 right-4": right,
      })}
    >
      <CaretLeft size={20} weight="bold" />
    </IconButton>
  );
}

CarouselArrow.defaultProps = {
  right: false,
};

export default CarouselArrow;
