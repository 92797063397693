import { useQuery } from "@tanstack/react-query";

import medicalStayOrga from "~/dataSamples/MedicalStayOrga";

export const useGetMedicalStayOrga = (interventionId) =>
  useQuery({
    queryKey: ["medicalStayOrga"],
    queryFn: () =>
      Promise.resolve(
        medicalStayOrga?.filter(
          (medicalStayDay) => medicalStayDay?.interventionId === interventionId
        )
      ),
    staleTime: 3600 * 24 * 1000, // 1 day
  });
