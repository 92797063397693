import pdfjsWorker from "pdfjs-dist/build/pdf.worker.entry";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

export default class PdfHelper {
  static toBase64Image = async (fileUri) => {
    const pdf = await pdfjs.getDocument({ url: fileUri }).promise;

    const canvas = document.createElement("canvas");
    canvas.setAttribute("className", "canv");

    var page = await pdf.getPage(1);
    var viewport = page.getViewport({ scale: 1 });
    canvas.height = viewport.height;
    canvas.width = viewport.width;

    var render_context = {
      canvasContext: canvas.getContext("2d"),
      viewport: viewport,
    };
    await page.render(render_context).promise;
    const img = canvas.toDataURL("image/png");

    return img;
  };
}
