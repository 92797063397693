import React from "react";

import classNames from "classnames";
import { Check } from "phosphor-react";
import PropTypes from "prop-types";

function Checkbox({
  id,
  name,
  checked,
  value,
  onChange,
  children,
  className,
  ...fieldValidationProps
}) {
  return (
    <label
      className={classNames(
        "flex flex-grow-0 flex-row gap-x-4 items-center cursor-pointer",
        className
      )}
    >
      <input
        id={id}
        name={name}
        className={`hidden peer`}
        type="checkbox"
        value={value}
        checked={checked}
        onChange={onChange}
        {...fieldValidationProps}
      />

      <div className="flex-shrink-0 flex-grow-0 peer-checked:hidden h-8 w-8 rounded-lg border border-neutral-700 hover:bg-yellow-400 peer-hover:bg-yellow-400" />
      <div className="flex-shrink-0 flex-grow-0 peer-[:not(:checked)]:hidden h-8 w-8 rounded-lg border border-yellow-500 bg-yellow-500 flex items-center justify-center">
        <Check size={24} />
      </div>
      <div className="flex-1">{children}</div>
    </label>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  value: PropTypes.any,
  className: PropTypes.string,
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  noErrorMessage: PropTypes.bool,
  children: PropTypes.node,
};

export default Checkbox;
