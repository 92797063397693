import {
  faFile,
  faFileCircleCheck,
  faFileInvoice,
  faFileMedical,
} from "@fortawesome/free-solid-svg-icons";

function IconDocument(type) {
  let iconDocument = null;

  switch (type) {
    default:
    case "ordonnance":
    case "consigne":
      iconDocument = faFileMedical;
      break;
    case "attestation":
      iconDocument = faFileCircleCheck;
      break;
    case "facture":
      iconDocument = faFileInvoice;
      break;
  }

  return iconDocument;
}

export default IconDocument;
