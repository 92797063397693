import { useQuery } from "@tanstack/react-query";

import appointments from "~/dataSamples/Appointments";

export const useGetAppointments = (medicalStayId) =>
  useQuery({
    queryKey: ["appointments"],
    queryFn: () =>
      Promise.resolve(
        appointments?.filter(
          (document) => document?.medicalStayId === medicalStayId
        )
      ),
    staleTime: 3600 * 24 * 1000, // 1 day
  });
