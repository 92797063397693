import React from "react";

import { CheckCircle, Eye, Trash } from "phosphor-react";
import PropTypes from "prop-types";

import IconButton from "~/components/iconButton/IconButton";
import NumberHelper from "~/helpers/NumberHelper";

import FilePreview from "./FilePreview";

function FileInfo({ description, file, onDelete }) {
  let size = 0;
  let unit = "ko";
  let extension = null;

  if (file) {
    size = Math.round((file?.size ?? 0) / 1000);
    if (size > 1000) {
      size /= 1000;
      unit = "mo";
    }

    const nameParts = file.name.split(".");
    extension = nameParts[nameParts.length - 1];
  }

  return file ? (
    <div className="flex flex-col items-center gap-y-4">
      <FilePreview file={file} />
      <div className="text-center font-[DMSans-Bold]">
        <div>{description} - Fichier valide</div>
        <div className="text-gray-700 text-xs">
          ({NumberHelper.truncateDecimals(size, 2)} {unit} - {extension})
        </div>
      </div>
      <div className="flex flex-row items-center gap-x-2">
        <IconButton onClick={onDelete}>
          <Eye weight="bold" />
        </IconButton>
        <IconButton onClick={onDelete} className="text-red-500">
          <Trash weight="bold" />
        </IconButton>
      </div>
    </div>
  ) : null;
}

FileInfo.propTypes = {
  description: PropTypes.string.isRequired,
  file: PropTypes.any,
  onDelete: PropTypes.func.isRequired,
};

export default FileInfo;
