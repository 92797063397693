import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";

import Button from "~/components/button/Button";
import Cloud from "~/images/cloud.svg";

import FileInfo from "./components/FileInfo";

function FileInput({
  instructions,
  description,
  onChange,
  name,
  id,
  fileTypes,
  ...validationFieldProps
}) {
  const files = validationFieldProps.value;

  const dropzone = useDropzone({
    maxFiles: 1,
    onDrop: onChange,
    noClick: true,
    noDrag: files,
  });

  delete validationFieldProps.value;
  delete validationFieldProps.onChange;

  return (
    <>
      <div {...dropzone.getRootProps()}>
        <input
          {...dropzone.getInputProps({
            ...validationFieldProps,
          })}
        />
        <div
          className={classNames(
            "border rounded-2xl min-h-[220px] flex flex-col items-center justify-center gap-y-5 overflow-hidden",
            {
              "border-dashed border-neutral-400 bg-[#F7F7F7]": !files,
              "border-[#E6F3EF] bg-[#E6F3EF]": files,
            }
          )}
        >
          {!files && !dropzone.isDragActive && (
            <>
              <img src={Cloud} alt="icone cloud" />
              <div className="text-center">
                {instructions} <br />
                OU
              </div>
              <Button
                onClick={dropzone.open}
                size="s"
                color="gradiant"
                variant="outline"
              >
                Joindre un fichier
              </Button>
            </>
          )}

          {!files && dropzone.isDragActive && (
            <div className="flex flex-1 w-full bg-neutral-200 items-center justify-center">
              <div>Déposer</div>
            </div>
          )}

          {files && (
            <FileInfo
              description={description}
              file={files}
              onDelete={() => {
                dropzone.acceptedFiles = [];
                onChange && onChange([]);
              }}
            />
          )}
        </div>
      </div>
    </>
  );
}

FileInput.propTypes = {
  instructions: PropTypes.string,
  description: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  name: PropTypes.string,
  id: PropTypes.string,
};

FileInput.defaultProps = {
  instructions: "Glisser-déposer mon fichier dans l'encart",
  description: "Fichier",
};

export default FileInput;
