import React from "react";

import { Field } from "formik";
import PropTypes from "prop-types";

import SelectBase from "~/components/forms_unvalidated/select/Select";

function ValidationSelect({
  id,
  name,
  placeholder,
  className,
  options,
  onChange,
  noErrorMessage,
  noOptionsMessage,
  isClearable,
  isMulti,
  isSearchable,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => (
          <SelectBase
            {...field}
            id={id}
            name={name}
            className={className}
            placeholder={placeholder}
            options={options}
            onChange={(value) => {
              form.setFieldValue(name, value ?? null);
              onChange && onChange(value ?? null);
            }}
            touched={meta.touched}
            error={meta.error}
            value={meta.value}
            noOptionsMessage={noOptionsMessage}
            noErrorMessage={noErrorMessage}
            isClearable={isClearable}
            isMulti={isMulti}
            isSearchable={isSearchable}
          />
        )}
      </Field>
    </>
  );
}

ValidationSelect.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  options: PropTypes.array.isRequired,
  onChange: PropTypes.func,
};

export default ValidationSelect;
