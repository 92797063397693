import React, { useEffect, useState } from "react";

import { Field, useFormikContext } from "formik";
import _ from "lodash";

import usePread from "~/contexts/usePread";

import Bookmark from "~/images/icons/bookmark.svg";
import classNames from "classnames";

function PackageSelector({ name, packages, initialValues, onChange }) {
  const { values } = useFormikContext();
  const [roomOptionsById, setRoomOptionsById] = useState({});

  const { roomOptions } = usePread();

  useEffect(() => {
    setRoomOptionsById(_.keyBy(roomOptions, "id"));
  }, [roomOptions, setRoomOptionsById]);

  const packageId = values[name] ?? null;

  let selectedPackage = null;
  let suggestedPackage = null;
  if (packageId) {
    selectedPackage = _.find(packages, (p) => p.id === +packageId);
    if (selectedPackage && !selectedPackage.bookingGuarantee) {
      suggestedPackage = _.find(packages, (p) => p.bookingGuarantee);
    }
  }

  return (
    <>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 items-end -mt-6 ">
        {packages?.map((p) => (
          <Field key={`${p.id}`} type="radio" name={name} value={`${p.id}`}>
            {({ field }) => {
              console.log(field);
              return (
                <label className="relative">
                  <input
                    type="radio"
                    className=" hidden peer"
                    {...field}
                    value={`${p.id}`}
                    onChange={(event) => {
                      onChange && onChange(event.target.value);
                      field?.onChange(event);
                    }}
                  />
                  <div
                    className=" flex flex-col  border border-gray-300 bg-white rounded-xl overflow-hidden 
                    peer-checked:border-2 peer-checked:border-[#141523] peer-checked:bg-gray-100
                    drop-shadow-md ld:max-w-xs"
                  >
                    {initialValues?.packageId == p?.id && (
                      <div
                        className="flex justify-center items-center bg-blue-700 py-3 space-x-2
                     text-white text-sm"
                      >
                        <img src={Bookmark} alt="Bookmark" />
                        <p>Offre la plus adaptée</p>
                      </div>
                    )}
                    <div className="flex flex-col space-y-2 px-4 py-3">
                      <p className="font-[Poppins-SemiBold]  text-3xl">
                        {p.name}
                      </p>
                      <hr />
                      <div className="font-[DMSans-Bold] text-sm text-gray-600 md:h-64">
                        <p className="md:hidden block ">
                          {p?.options?.length} services
                        </p>
                        <ul className="hidden md:block space-y-2 ">
                          {p?.options.map((id) => {
                            const option = roomOptionsById?.[id];
                            return (
                              <li
                                key={`${id}`}
                                className="flex items-center space-x-1"
                              >
                                <img
                                  src={option?.icon}
                                  alt={`Option icon ${id}`}
                                  className="w-4"
                                />
                                <p className="text-sm font-base leading-3">
                                  {option.name}
                                </p>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                      <div className="self-end flex  items-center h-10 shrink-0 pt-4">
                        <span
                          className={classNames("text-black", {
                            "font-[Poppins-Bold] text-4xl": field?.checked,
                            "font-[Poppins-SemiBold] text-3xl": !field?.checked,
                          })}
                        >
                          {p.pricePerDay}€
                        </span>{" "}
                        <span className="font-[DMSans-Bold] text-sm text-gray-600">
                          / jour
                        </span>
                      </div>
                    </div>
                    <div
                      className={classNames(
                        "flex justify-center items-center  py-3 space-x-2 text-white text-sm ",
                        {
                          "bg-blue-600": field?.checked,
                          "bg-black": !field?.checked,
                        }
                      )}
                    >
                      {field?.checked ? (
                        <p> Offre sélectionnée</p>
                      ) : (
                        <p> Séléctionner</p>
                      )}
                    </div>
                  </div>
                </label>
              );
            }}
          </Field>
        ))}
      </div>
    </>
  );
}

export default PackageSelector;
