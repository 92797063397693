import React from "react";

import Tippy from "@tippyjs/react";
import classNames from "classnames";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

function Button({
  href,
  target,
  type,
  form,
  onClick,
  children,
  color,
  size,
  className,
  block,
  variant,
  roundedFull,
  noShadow,
  startIcon,
  endIcon,
  tooltip,
  disabled,
}) {
  const ContainerClass = tooltip ? Tippy : React.Fragment;
  const containerProps = tooltip
    ? { content: tooltip, placement: "top" }
    : null;

  let Comp = null;
  let props = {};

  if (href) {
    if (href.startsWith("http")) {
      // it's an external link, we can't use react-router
      Comp = "a";
      props.href = href;
    } else {
      Comp = Link;
      props.to = href;
    }

    props.target = target;
  } else {
    Comp = "button";
    props.onClick = onClick;
    props.type = type;
    props.form = form;
  }

  let colorClass = null;
  switch (color) {
    default:
    case "yellow":
      switch (variant) {
        default:
        case "default":
          colorClass = "bg-yellow-500 text-black ";
          break;
        case "outline":
          colorClass = "border-4 border-yellow-500 bg-[#FFFBF3] text-black";
          break;
      }

      break;
    case "white":
      switch (variant) {
        default:
        case "default":
          colorClass =
            "bg-white hover:bg-white active:bg-white text-black border border-gray-300";
          break;
        case "outline":
          colorClass = "border-transparent hover:bg-white active:bg-white";
          break;
      }
      break;
    case "black":
      switch (variant) {
        default:
        case "default":
          colorClass = "bg-black hover:bg-black active:bg-black text-white ";
          break;
        case "outline":
          colorClass = "border-transparent hover:bg-white active:bg-white";
          break;
      }
      break;
    case "gradiant":
      switch (variant) {
        default:
        case "default":
          colorClass =
            "bg-gradient-to-br from-[#F9B004] to-[#FBCC5C] text-black ";
          break;
        case "outline":
          colorClass =
            "bg-gradient-to-br from-[#F9B004] to-[#FBCC5C] text-black border-2 border-black";
          break;
      }
      break;
    case "transparent":
      switch (variant) {
        default:
        case "default":
          colorClass =
            "bg-transparent hover:bg-neutral-300 disabled:text-gray-400 border border-gray-300";
          break;
        case "outline":
          colorClass = "bg-transparent border-none";
          break;
      }
      break;
  }

  let sizeClass = null;
  switch (size) {
    case "s":
      sizeClass = "md:px-6 px-2 py-3 text-sm rounded-2xl";
      break;
    default:
    case "m":
      sizeClass = "md:px-8 px-4 py-4 font-bold text-base rounded-2xl";
      break;
    case "l":
      sizeClass = "md:px-8 px-4 py-4 font-bold text-xl rounded-2xl";
      break;
  }

  return (
    <ContainerClass {...containerProps}>
      <Comp
        {...props}
        className={classNames(
          "flex flex-row justify-center items-center gap-x-2 focus-visible:outline-none text-center font-bold lg:text-sm text-xs",
          colorClass,
          sizeClass,
          {
            // block
            "w-full justify-center": block,
            "w-auto": !block,
            // others
            border: variant === "outline",
            "rounded-full": roundedFull,
            shadow: !noShadow,
          },
          className
        )}
        disabled={disabled}
      >
        {startIcon && <span className="text-xl">{startIcon}</span>}
        {children}
        {endIcon && <span className="text-xl">{endIcon}</span>}
      </Comp>
    </ContainerClass>
  );
}

Button.propTypes = {
  href: PropTypes.string,
  target: PropTypes.oneOf(["_blank", "_self", "_parent", "_top"]),
  type: PropTypes.oneOf(["button", "submit", "reset"]),
  form: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node.isRequired,
  color: PropTypes.oneOf([
    "blue",
    "lightBlue",
    "yellow",
    "lightYellow",
    "gradiant",
    "black",
    "transparent",
    "danger",
    "white",
    "green",
  ]),
  size: PropTypes.string,
  className: PropTypes.string,
  block: PropTypes.bool,
  noShadow: PropTypes.bool,
  roundedFull: PropTypes.bool,
  variant: PropTypes.oneOf(["default", "outline"]),
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
  tooltip: PropTypes.node,
  disabled: PropTypes.bool,
};

Button.defaultProps = {
  type: "button",
  size: "m",
  color: "yellow",
  variant: "default",
  className: "",
  noShadow: false,
  block: false,
  disabled: false,
};

export default Button;
