import React from "react";

import classNames from "classnames";
import { Check } from "phosphor-react";

function Stepper({ steps, activeIndex }) {
  return (
    <div className="flex flex-row gap-x-2">
      {steps.map((step, index) => (
        <div key={`${index}`} className="flex flex-col space-y-1 w-[174px]">
          <div
            className={classNames("w-full h-1 rounded-full drop-shadow-lg", {
              "bg-black": activeIndex > index,
              "bg-blue-600": activeIndex === index,
              "bg-white": activeIndex < index,
            })}
          />
          <div className="flex items-center space-x-2 text-black">
            {activeIndex > index && <Check size="20" />}
            <p
              className={classNames("xl:text-lg md:text-base text-sm", {
                "text-black font-semibold": activeIndex === index,
                "text-gray-600": activeIndex < index,
              })}
            >
              {step.text}
            </p>
          </div>
        </div>
      ))}
    </div>
  );
}

function OldStepper({ steps, activeIndex }) {
  return (
    <div className="flex flex-row gap-x-0">
      {steps.map((step, index) => (
        <div key={`${index}`} className="w-[560px]">
          <div className="flex flex-row flex-1 items-center text-xs">
            <div
              className={classNames("flex-1 ", {
                "h-0.5": index > 0,
                "bg-black": activeIndex >= index,
                "bg-white": activeIndex < index,
              })}
            />
            <div
              className={classNames(
                "rounded-full h-6 w-6 flex items-center justify-center",
                {
                  "text-white bg-black": activeIndex >= index,
                  "bg-white": activeIndex < index,
                }
              )}
            >
              {activeIndex > index ? <Check weight="bold" /> : index + 1}
            </div>
            <div
              className={classNames("flex-1 ", {
                "h-0.5": index < steps.length - 1,
                "bg-black": activeIndex > index,
                "bg-white": activeIndex <= index,
              })}
            />
          </div>
          <div className="text-center text-xs md:text-sm lg:text-base">
            {step.text}
          </div>
        </div>
      ))}
    </div>
  );
}

export default Stepper;
