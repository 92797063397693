const roomPackages = [
  {
    id: 1,
    name: "Solo",
    pricePerDay: 97,
    options: [1, 3],
    photos: [1, 2, 3],
    bookingGuarantee: false,
  },
  {
    id: 2,
    name: "Confort",
    pricePerDay: 114,
    options: [1, 3, 4, 5, 8, 12],
    photos: [1, 2, 3, 4],
    bookingGuarantee: false,
  },
  {
    id: 3,
    name: "Privilège",
    pricePerDay: 157,
    options: [1, 2, 3, 4, 5, 6, 7, 8, 9, 11, 12],
    photos: [1, 2, 3, 4, 5, 6],
    bookingGuarantee: true,
  },
];

export default roomPackages;
