import React from "react";

import classNames from "classnames";

function CarouselDot({ style = "yellow", active, onClick }) {
  return (
    <li className="w-full">
      {style === "yellow" ? (
        <button
          type="button"
          className={classNames("w-full h-1", {
            "bg-[#FFC01B]": active,
            "bg-[#D0D0D3]": !active,
          })}
          onClick={onClick}
        />
      ) : (
        <button
          type="button"
          className={classNames("w-full h-1", {
            "bg-[#ffffff]": active,
            "bg-[#5F6C85]": !active,
          })}
          onClick={onClick}
        />
      )}
    </li>
  );
}

export default CarouselDot;
