import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function IconCircle({ icon, size, color = "darkblue" }) {
  return (
    <div className="flex items-center justify-center bg-yellow-100 rounded-full w-10 h-10 shrink-0">
      <FontAwesomeIcon icon={icon} size={size} color={color} />
    </div>
  );
}

export default IconCircle;
