import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import BottomLeft from "~/images/decorations/CircularSideBottomLeftBlock.svg";
import MiddleRight from "~/images/decorations/CircularSideBlock.svg";
import TopRight from "~/images/decorations/CircularSideTopRightBlock.svg";

function Block({ decoration, bgColor, className, children, size }) {
  let decorationImg = "";
  let decorationStyle = "";

  switch (decoration) {
    case "middleRight":
      decorationImg = MiddleRight;
      decorationStyle = "top-1/2 right-0  -translate-y-1/2";
      break;
    case "bottomLeft":
      decorationImg = BottomLeft;
      decorationStyle = "bottom-0 left-0";
      break;
    case "topRight":
      decorationImg = TopRight;
      decorationStyle = "top-0 right-0";
    default:
      break;
  }

  return (
    <div
      className={classNames(
        "relative border-neutral-300 border-2 rounded-2xl bg-white overflow-hidden",
        {
          "p-0": size === "xs",
          "p-4": size === "s",
          "py-16 px-8": size === "m",
        },
        bgColor
      )}
    >
      {decoration && (
        <div className={`absolute transform ${decorationStyle}`}>
          <img src={decorationImg} alt="Decoration" />
        </div>
      )}
      <div className={`z-10 ${className} `}>{children}</div>
    </div>
  );
}

Block.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  size: PropTypes.oneOf(["s", "m"]),
};

Block.defaultProps = {
  className: "",
  size: "m",
};

export default Block;
