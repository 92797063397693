import React from "react";

import classNames from "classnames";
import { CaretDown } from "phosphor-react";
import PropTypes from "prop-types";

import useToggle from "~/hooks/useToggle";

import IconButton from "../iconButton/IconButton";

function Panel({
  title,
  subtitle,
  icon,
  headerContent,
  children,
  noHeader,
  className,
  collapsible,
  expandedByDefault,
}) {
  const [expanded, toggleExpanded] = useToggle(expandedByDefault);

  return (
    <div
      className={classNames(
        "rounded-2xl drop-shadow-md overflow-hidden w-full",
        className
      )}
    >
      {!noHeader && (
        <div className="bg-neutral-50 p-8 text-neutral-900">
          <div className="flex flex-shrink-0 flex-row gap-5">
            {icon && <div>{icon}</div>}
            <div className="flex flex-1 flex-col space-y-2">
              <div className="text-2xl font-[Poppins-SemiBold]">{title}</div>
              <div className="text-sm">{subtitle}</div>
            </div>
            {collapsible && (
              <IconButton onClick={toggleExpanded}>
                <div className={classNames({ "rotate-180": !expanded })}>
                  <CaretDown weight="bold" />
                </div>
              </IconButton>
            )}
          </div>
          {headerContent && <div className="mt-4">{headerContent}</div>}
        </div>
      )}
      {expanded && (
        <div className="border-0 border-t border-neutral-500 p-8 bg-white">
          {children}
        </div>
      )}
    </div>
  );
}

Panel.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  icon: PropTypes.element,
  headerContent: PropTypes.node,
  noHeader: PropTypes.bool,
  collapsible: PropTypes.bool,
  expandedByDefault: PropTypes.bool,
  children: PropTypes.node,
};

Panel.defaultProps = {
  noHeader: false,
  className: "",
  collapsible: false,
  expandedByDefault: true,
};

export default Panel;
