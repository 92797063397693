import React, { useRef } from "react";

import {
  Bed,
  CaretRight,
  Plugs,
  PlugsConnected,
  Shower,
  TestTube,
} from "phosphor-react";
import { Link } from "react-router-dom";

import IconButton from "~/components/iconButton/IconButton";
import useLayout from "~/hooks/useLayout";
import useMount from "~/hooks/useMount";
import SigemsLogo from "~/images/sigems-logo.jpg";
import Web100TLogo from "~/images/web100t-logo.jpg";

import GAPModal from "./components/GAPModal";

function HomePage() {
  const { setLayout } = useLayout();

  const guidModal = useRef();
  const ippModal = useRef();

  useMount(() => {
    setLayout({
      title: "Accueil",
      type: "Welcome",
    });
  });

  return (
    <>
      <div className="flex flex-col space-y-4 py-6">
        <p className="text-5xl font-bold">Bienvenue !</p>
        <p className="text-xl">
          Veuillez selectionner le type de POC que vous voulez tester
        </p>
      </div>
      <div className="flex flex-col gap-y-6 py-6">
        <Link
          to="/dashboard"
          className="flex flex-row items-center p-6 gap-x-4 rounded-lg shadow-lg border border-gray-100 hover:cursor-pointer hover:shadow-xl hover:border-gray-300"
        >
          <div className="hidden md:flex">
            <TestTube size={48} weight="duotone" className="text-blue-500" />
          </div>
          <div className="flex flex-1 flex-col gap-y-1">
            <div className="flex flex-row space-x-1 items-center">
              <Plugs size={16} weight="duotone" />
              <p className="text-sm">mode déconnecté</p>
            </div>
            <p className="text-base px-1 tight ">
              Démonstration avec des données de Test
            </p>
          </div>
          <IconButton onClick={null}>
            <CaretRight size={16} weight="bold" />
          </IconButton>
        </Link>
        <div
          className="flex flex-row items-center p-6 gap-x-4 rounded-lg shadow-lg border border-gray-100 hover:cursor-pointer hover:shadow-xl hover:border-gray-300"
          onClick={() => ippModal?.current?.open()}
        >
          <img src={SigemsLogo} className="hidden md:flex w-28" alt="Sigems" />
          <div className="flex flex-1 flex-col gap-y-1">
            <div className="flex flex-row space-x-1 items-center">
              <PlugsConnected size={16} weight="duotone" />
              <p className="text-sm">mode connecté</p>
            </div>
            <p className="text-base px-1">Démonstration avec la GAP SIGEMS</p>
          </div>
          <IconButton onClick={null}>
            <CaretRight size={16} weight="bold" />
          </IconButton>
        </div>
        <div
          className="flex flex-row items-center p-6 gap-x-4 rounded-lg shadow-lg border border-gray-100 hover:cursor-pointer hover:shadow-xl hover:border-gray-300"
          onClick={() => guidModal?.current?.open()}
        >
          <img src={Web100TLogo} className="hidden md:flex w-28" alt="Sigems" />
          <div className="flex flex-1 flex-col gap-y-1">
            <div className="flex flex-row space-x-1 items-center">
              <PlugsConnected size={16} weight="duotone" />
              <p className="text-sm">mode connecté</p>
            </div>
            <p className="text-base px-1">Démonstration avec la GAP WEB100T</p>
          </div>
          <IconButton onClick={null}>
            <CaretRight size={16} weight="bold" />
          </IconButton>
        </div>
      </div>
      <GAPModal ref={ippModal} gap="SIGEMS" />
      <GAPModal ref={guidModal} gap="WEB100T" />
    </>
  );
}

export default HomePage;
