import React from "react";

import { CaretRight } from "phosphor-react";
import PropTypes from "prop-types";

import IconButton from "~/components/iconButton/IconButton";

function NewsCarouselItem({ news }) {
  return (
    <div className="p-6 bg-white rounded-2xl shadow flex flex-col flex-1 gap-y-5">
      <img
        src={news.image}
        className="self-center rounded-2xl object-cover w-full aspect-square"
        alt="illustration"
      />
      <div className="font-[Poppins-SemiBold] text-lg">#{news.title}</div>
      <div className="flex flex-row items-center">
        <div className="flex flex-1 font-[DMSans-Regular] text-lg">
          {news.description}
        </div>
        <IconButton className="bg-yellow-500" noShadow>
          <CaretRight size={16} weight="bold" />
        </IconButton>
      </div>
    </div>
  );
}

NewsCarouselItem.propTypes = {
  news: PropTypes.object.isRequired,
};
export default NewsCarouselItem;
