import React from "react";

import { Field } from "formik";

import RadioButtonsGroupBase from "~/components/forms_unvalidated/radioButtonsGroup/RadioButtonsGroup";

function ValidationRadioButtonsGroup({
  id,
  name,
  values,
  disabled,
  checked,
  children,
  onChange,
}) {
  return (
    <Field type="radio" name={name} checked={checked}>
      {({ field, form }) => {
        return (
          <RadioButtonsGroupBase
            id={id}
            name={name}
            disabled={disabled}
            values={values}
            {...field}
            onChange={(e) => {
              field.onChange(e);
              onChange && onChange(e, form);
            }}
          >
            {children}
          </RadioButtonsGroupBase>
        );
      }}
    </Field>
  );
}

export default ValidationRadioButtonsGroup;
