import { useEffect } from "react";

import PropTypes from "prop-types";

/* 
  SOURCE: https://dev.to/diegocasmo/scroll-to-input-on-formik-failed-submission-1c3c
  modifié car scrollait sur le 1er champ par ordre alphabétique 
 */

/**
 * Returns an array of error field names using object dot notation for
 * array fields (if any)
 * Example:
 * Input: { name: 'is invalid', items: [{ description: 'is invalid' }] }
 * Output: ['name', 'items.0.description']
 * @param {Object} errors A Formik form errors
 * @returns {Array}
 */
const getFieldErrorNames = (formikErrors) => {
  const transformObjectToDotNotation = (obj, prefix = "", result = []) => {
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (!value) return;

      const nextKey = prefix ? `${prefix}.${key}` : key;
      if (typeof value === "object") {
        transformObjectToDotNotation(value, nextKey, result);
      } else {
        result.push(nextKey);
      }
    });

    return result;
  };

  return transformObjectToDotNotation(formikErrors);
};

function ScrollToFieldError({ formik: { isValid, errors, isSubmitting } }) {
  useEffect(() => {
    if (isValid || !isSubmitting) return;

    const fieldErrorNames = getFieldErrorNames(errors);
    if (fieldErrorNames.length <= 0) return;

    const elements = document.querySelector(".form-validation-error");

    if (!elements || elements.length === 0) return;

    const element = elements;

    if (!element) return;

    // Scroll to first known error into view
    element.previousElementSibling.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
    element.previousElementSibling.focus();
  }, [isValid, errors, isSubmitting]);

  return null;
}

ScrollToFieldError.propTypes = {
  formik: PropTypes.object.isRequired,
};

export default ScrollToFieldError;
