import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";
import { IMaskInput } from "react-imask";

import { getInputClassName } from "../input/Input";
import InputErrorMessage from "../inputErrorMessage/InputErrorMessage";

function MaskedInput({
  id,
  name,
  mask,
  touched,
  error,
  className,
  value,
  onChange,
  autoComplete,
  noErrorMessage,
  ...fieldValidationProps
}) {
  return (
    <>
      <IMaskInput
        id={id}
        name={name}
        type="text"
        className={classNames(
          "form-input",
          getInputClassName(touched, error, className)
        )}
        {...fieldValidationProps}
        value={value ?? ""}
        onChange={onChange}
        autoComplete={autoComplete}
        mask={mask}
      />
      {touched && error && !noErrorMessage && (
        <InputErrorMessage message={error} />
      )}
    </>
  );
}
MaskedInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string.isRequired,
  mask: PropTypes.string.isRequired,
  className: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

MaskedInput.defaultProps = {
  value: "",
  className: "",
  touched: false,
  error: null,
  autoComplete: "off",
  noErrorMessage: false,
};

export default MaskedInput;
