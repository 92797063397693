import { useContext, createContext, useState, useCallback } from "react";

import _ from "lodash";

const preadContext = createContext();

const usePread = () => {
  return useContext(preadContext);
};

export default usePread;

function useProvidePread() {
  const [initialized, setInitialized] = useState(false);

  const [roomOptions, innerSetRoomOptions] = useState();
  const [roomPhotos, innerSetRoomPhotos] = useState();
  const [roomPackages, setRoomPackages] = useState();

  const [medicalStay, setMedicalStay] = useState(null);
  const [config, setConfig] = useState(null);
  const [patienRoomWishes, setPatientRoomWishes] = useState();

  const initialize = (
    medicalStay,
    config,
    roomReservationOptions,
    roomReservationPackages,
    roomReservationPhotos
  ) => {
    setMedicalStay(medicalStay);
    setConfig(config);

    innerSetRoomOptions(roomReservationOptions);

    setRoomPackages(roomReservationPackages);

    innerSetRoomPhotos(roomReservationPhotos);

    setInitialized(true);
  };

  const savePatientData = useCallback(
    (data) => {
      let newMedicalStay = {
        ...medicalStay,
        patient: { ...medicalStay.patient },
      };
      newMedicalStay.patient.sex = data.sex;
      newMedicalStay.patient.birthName = data.birthName;
      newMedicalStay.patient.lastName = data.lastName;
      newMedicalStay.patient.firstName = data.firstName;
      newMedicalStay.patient.birthDate = data.birthDate;
      newMedicalStay.patient.address = data.address;
      newMedicalStay.patient.postCode = data.postCode;
      newMedicalStay.patient.city = data.city;
      newMedicalStay.patient.email = data.email;
      newMedicalStay.patient.mobilePhone = data.mobilePhone;
      setMedicalStay(newMedicalStay);
    },
    [medicalStay]
  );

  const saveCompanionsData = useCallback(
    (data) => {
      let newMedicalStay = {
        ...medicalStay,
        trustedPerson: { ...medicalStay.trustedPerson },
      };
      newMedicalStay.trustedPerson.firstName = data.trustedPersonFirstName;
      newMedicalStay.trustedPerson.lastName = data.trustedPersonLastName;
      newMedicalStay.trustedPerson.email = data.trustedPersonEmail;
      newMedicalStay.trustedPerson.mobilePhone = data.trustedPersonMobilePhone;
      newMedicalStay.trustedPerson.relationshipType =
        data.trustedPersonRelationshipType;

      setMedicalStay(newMedicalStay);
    },
    [medicalStay]
  );

  return {
    initialized,
    // config
    config,
    medicalStay,
    roomOptions,
    roomPackages,
    roomPhotos,
    patienRoomWishes,

    // methods
    initialize,
    savePatientData,
    saveCompanionsData,
    setPatientRoomWishes,
  };
}

export function ProvidePread({ children }) {
  const adminInfos = useProvidePread();

  return (
    <preadContext.Provider value={adminInfos}>{children}</preadContext.Provider>
  );
}
