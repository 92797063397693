import React from "react";

import Block from "~/components/block/Block";
import Button from "~/components/button/Button";
import IconCircle from "~/components/iconCircle/IconCircle";

function ToDoCarouselItem({ title, subTitle, icon, link, decoration = "" }) {
  return (
    <Block
      size="s"
      decoration={decoration}
      className="flex flex-col justify-between gap-y-4 "
    >
      <div className="flex items-center gap-x-2 w-full">
        <IconCircle icon={icon} size="lg" />
        <p className="font-[Poppins-SemiBold]">{title}</p>
      </div>
      <div className="flex">
        <p>{subTitle}</p>
      </div>
      <div className="flex self-center">
        <Button size="s" variant="outline">
          Découvrir
        </Button>
      </div>
    </Block>
  );
}

export default ToDoCarouselItem;
