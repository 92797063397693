import React from "react";

import { Field } from "formik";

import PhoneInputBase from "~/components/forms_unvalidated/phoneNumberInput/PhoneNumberInput";

function ValidationPhoneNumberInput({
  id,
  name,
  className,
  onChange,
  noErrorMessage,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <PhoneInputBase
              id={id}
              className={className}
              {...field}
              touched={meta.touched}
              error={meta.error}
              value={meta.value ?? ""}
              onChange={(value) => {
                onChange && onChange(value, form);
                form.setFieldValue(name, value ?? "");
              }}
              noErrorMessage={noErrorMessage}
            />
          );
        }}
      </Field>
    </>
  );
}

ValidationPhoneNumberInput.propTypes = {
  id: PhoneInputBase.propTypes.id,
  name: PhoneInputBase.propTypes.name,
  className: PhoneInputBase.propTypes.className,
  onChange: PhoneInputBase.propTypes.onChange,
  autoComplete: PhoneInputBase.propTypes.autoComplete,
  noErrorMessage: PhoneInputBase.propTypes.noErrorMessage,
};

ValidationPhoneNumberInput.defaultProps = {
  value: PhoneInputBase.defaultProps.value,
  className: PhoneInputBase.defaultProps.className,
  noErrorMessage: PhoneInputBase.defaultProps.noErrorMessage,
};

export default ValidationPhoneNumberInput;
