import React from "react";

import classNames from "classnames";
import { CaretDown } from "phosphor-react";

import useToggle from "~/hooks/useToggle";

function Collapsible({ title, price, children }) {
  const [opened, toggle] = useToggle();

  return (
    <div>
      <div className="flex flex-row items-center gap-x-3">
        <div
          onClick={() => toggle()}
          className={classNames(
            "flex flex-shrink-0 flex-grow-0 bg-neutral-300 items-center justify-center rounded-lg h-6 w-6",
            { "rotate-180": opened }
          )}
        >
          <CaretDown weight="bold" size="10" />
        </div>
        <div className="flex flex-1 font-[DMSans-Regular] text-sm">{title}</div>
        <div>
          <span className="font-[Poppins-SemiBold]">{price}</span>
          <span className="font-[DMSans-Regular] text-sm">
            €{price > 0 && <>/j</>}
          </span>
        </div>
      </div>
      {opened && <div>{children}</div>}
    </div>
  );
}

export default Collapsible;
