import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import { getInputClassName } from "../input/Input";
import MaskedInput from "../maskedInput/MaskedInput";

export const homePhoneNumberRegex = /(0)[123459][0-9]{8}/g;

export const mobilePhoneNumberRegex = /(0)[67][0-9]{8}/g;

function PhoneNumberInput({
  id,
  name,
  touched,
  error,
  className,
  value,
  onChange,
  autoComplete,
  noErrorMessage,
  ...fieldValidationProps
}) {
  const handleChange = (event) => {
    const value = event.target.value.replaceAll(" ", "");
    onChange && onChange(value);
  };

  return (
    <>
      <MaskedInput
        id={id}
        name={name}
        type="text"
        className={classNames(
          "form-input",
          getInputClassName(touched, error, className)
        )}
        {...fieldValidationProps}
        value={value ?? ""}
        onChange={handleChange}
        autoComplete={autoComplete}
        mask={`00 00 00 00 00`}
        noErrorMessage={noErrorMessage}
      />
    </>
  );
}
PhoneNumberInput.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  noErrorMessage: PropTypes.bool,
};

PhoneNumberInput.defaultProps = {
  value: "",
  className: "",
  touched: false,
  error: null,
  autoComplete: "off",
  noErrorMessage: false,
};

export default PhoneNumberInput;
