const preadConfig = {
  sexes: [
    {
      label: "Homme",
      value: "1",
    },
    {
      label: "Femme",
      value: "2",
    },
  ],
  relationshipTypes: [
    {
      label: "Parent",
      value: "parent",
    },
    {
      label: "Enfant",
      value: "child",
    },
    {
      label: "Ami",
      value: "friend",
    },
    {
      label: "Tutelle",
      value: "tutor",
    },
  ],
};

export default preadConfig;
