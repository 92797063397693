import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconDocument from "../iconDocument/IconDocument";

function InlineDocument({ document, size = "md" }) {
  return (
    <div className="flex items-center justify-between border-2 rounded-xl px-4 py-2">
      <div className="flex flex-col justify-center">
        <div className="flex flex-col xl:flex-row xl:items-center gap-x-2 ">
          <p className="">{document.title}</p>
          {document?.status && document?.status === "Nouveau" && (
            <div className="flex">
              <div className="flex  items-center rounded-full bg-[#FFE8E5] px-2 py-1 font-semibold">
                <p className="text-xs font-semibold text-[#B34000]">Nouveau</p>
              </div>
            </div>
          )}
        </div>
        <p className="text-gray-500 text-xs">
          {document.fileName} - {document.size}
        </p>
      </div>
      <FontAwesomeIcon icon={IconDocument(document.type)} size="2xl" />
    </div>
  );
}

export default InlineDocument;
