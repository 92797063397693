import React, { forwardRef, useImperativeHandle } from "react";
import { useRef } from "react";

import { Formik } from "formik";
import PropTypes from "prop-types";

import ScrollToFieldError from "../scrollToFieldError/ScrollToFieldError";

const ValidationForm = forwardRef(
  (
    {
      onSubmit,
      initialValues,
      validate,
      validationSchema,
      enableReinitialize = false,
      children,
      ...formProps
    },
    ref
  ) => {
    const innerRef = useRef(null);

    return (
      <Formik
        initialValues={initialValues ?? {}}
        validationSchema={validationSchema ?? null}
        validate={validate}
        onSubmit={onSubmit}
        enableReinitialize={enableReinitialize}
        innerRef={innerRef}
      >
        {(formik) => (
          <FormWithFormik
            ref={ref}
            innerRef={innerRef}
            {...formProps}
            formik={formik}
          >
            <ScrollToFieldError formik={formik} />
            {typeof children === "function" ? children(formik) : children}
          </FormWithFormik>
        )}
      </Formik>
    );
  }
);

ValidationForm.propTypes = {
  onSubmit: PropTypes.func,
  initialValues: PropTypes.object.isRequired,
  validate: PropTypes.any,
  validationSchema: PropTypes.object,
  enableReinitialize: PropTypes.bool,
  children: PropTypes.any,
};

export default ValidationForm;

const FormWithFormik = forwardRef(
  ({ formik, innerRef, children, ...formProps }, ref) => {
    useImperativeHandle(ref, () => ({
      setErrors(errors) {
        formik?.setErrors(errors);
      },

      reset(nextState = null) {
        formik?.resetForm(nextState);
      },

      setFieldValue(name, value, shouldValidate = true) {
        formik?.setFieldValue(name, value, shouldValidate);
      },

      getValues() {
        return innerRef.current.values;
      },
    }));

    return (
      <form
        onSubmit={formik.handleSubmit}
        {...formProps}
        noValidate
        autoComplete="off"
      >
        {typeof children === "function" ? children(formik) : children}
      </form>
    );
  }
);
