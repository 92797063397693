import React, { useEffect, useRef, useState } from "react";

import _ from "lodash";
import Carousel from "react-multi-carousel";

import Block from "~/components/block/Block";
import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import ValidationCheckbox from "~/components/forms/validationCheckbox/ValidationCheckbox";
import ValidationForm from "~/components/forms/validationForm/ValidationForm";
import Panel from "~/components/panel/Panel";
import usePread from "~/contexts/usePread";

import Header from "../../preadmission/components/Header";

import Comfort from "~/images/illustrations/comfort.png";

import CarouselArrow from "./CarouselArrow";
import CarouselPagination from "./CarouselPagination";
import Collapsible from "./Collapsible";
import PackageSelector from "./PackageSelector";
import StickyFooter from "./stickyFooter";
import PageHeader from "~/components/pageHeader/PageHeader";
import { CaretLeft, CaretRight } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";

import Informations from "~/images/icons/informations.svg";

import HandHoldingMedical from "~/images/icons/hand-holding-medical.svg";

const carouselBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 1,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
  },
};

export const packageStepPageParameters = {
  title: "Offre proposée",
  subTitle: "Nous avons trouvé l’offre qui correspond le plus à vos souhaits !",
  image: Comfort,
  imageTitle: "offre",
  imageNoShift: true,
};

function PackageStep({ onSubmitted, onPrevious }) {
  const navigate = useNavigate();

  const { serialNumber } = useParams();

  const [roomOptionsById, setRoomOptionsById] = useState({});
  const [roomPhotosById, setRoomPhotosById] = useState({});

  const { roomOptions, roomPackages, roomPhotos, patienRoomWishes } =
    usePread();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    setRoomOptionsById(_.keyBy(roomOptions, "id"));
  }, [roomOptions, setRoomOptionsById]);

  useEffect(() => {
    setRoomPhotosById(_.keyBy(roomPhotos, "id"));
  }, [roomPhotos, setRoomPhotosById]);

  const [orderedRoomPackages, setOrderedRoomPackages] = useState(null);
  const [photosByPackageId, setPhotosByPackageId] = useState({});

  const [initialValues, setInitialValues] = useState({});

  const [carouselCurrentIndex, setCarouselCurrentIndex] = useState(0);

  const carouselRef = useRef();

  useEffect(() => {
    const orderedPackages = _.orderBy(roomPackages, "pricePerDay", "asc");
    setOrderedRoomPackages(orderedPackages);

    let packagesPhotos = {};
    for (const pack of orderedPackages) {
      packagesPhotos[pack.id] = [];
      for (const photoId of pack.photos) {
        if (roomPhotosById.hasOwnProperty(photoId)) {
          packagesPhotos[pack.id].push(roomPhotosById[photoId]);
        }
      }
    }

    setPhotosByPackageId(packagesPhotos);
  }, [roomPackages, roomPhotosById, setOrderedRoomPackages]);

  useEffect(() => {
    if (!orderedRoomPackages || !patienRoomWishes) {
      return;
    }

    let formValues = {
      noPrivateRoom: false,
      selectedOptions: [],
    };

    const wantedOptionsIds = patienRoomWishes.roomOptions;

    orderedRoomPackages.every((pack) => {
      const hasAllOptions = wantedOptionsIds.every((id) =>
        pack.options.includes(+id)
      );

      if (hasAllOptions) {
        formValues.packageId = pack.id;
      }

      return !hasAllOptions;
    });

    formValues.packageId ??=
      orderedRoomPackages[orderedRoomPackages.length - 1]?.id;

    formValues.packageId = `${formValues.packageId}`;
    setInitialValues(formValues);
  }, [orderedRoomPackages, patienRoomWishes]);

  const onSubmit = (formData, { setSubmitting }) => {
    onSubmitted(onSubmitted);
    setSubmitting(false);
    navigate(`/medicalStays/${serialNumber}`);
  };

  console.log("orderedRoomPackages", orderedRoomPackages);

  return (
    <>
      <Container className="mb-32">
        <ValidationForm
          id="offerForm"
          onSubmit={onSubmit}
          initialValues={initialValues}
          enableReinitialize
        >
          {({ values, setFieldValue }) => {
            const selectedPackage = _.find(
              roomPackages,
              (p) => p.id === +values.packageId
            );

            let missingOptions = [];
            roomOptions?.forEach((opt) => {
              if (!selectedPackage?.options.includes(opt.id)) {
                missingOptions.push(opt);
              }
            });

            const selectedOptions =
              values.selectedOptions?.map((opt) => roomOptionsById[opt]) ?? [];

            let selectedOptionsPrice = 0;
            selectedOptions.forEach((option) => {
              selectedOptionsPrice += option.price;
            });

            const packagePhotos = photosByPackageId?.[+values.packageId] ?? [];

            return (
              <div
                className="grid grid-cols-1 
              md:grid-cols-3 xl:grid-cols-5 gap-6 w-full"
              >
                <div className="col-span-3 mt-6 md:mt-0 space-y-8">
                  <div className="flex flex-col space-y-4">
                    <PackageSelector
                      name="packageId"
                      packages={orderedRoomPackages}
                      initialValues={initialValues}
                      onChange={() => {
                        setFieldValue("selectedOptions", []);
                        carouselRef?.current?.goToSlide(0);
                      }}
                    />

                    <div className="flex items-center justify-center">
                      <Button
                        size="s"
                        id="compareOffers"
                        name="compareOffers"
                        noShadow
                        variant="outline"
                      >
                        Comparer les offres
                      </Button>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-8 ">
                    <Block size="xs">
                      <div className=" relative">
                        <Carousel
                          ref={carouselRef}
                          swipeable
                          draggable
                          showDots={false}
                          keyBoardControl
                          responsive={carouselBreakpoints}
                          customLeftArrow={<CarouselArrow />}
                          customRightArrow={<CarouselArrow right />}
                          afterChange={(previousSlide, state) => {
                            setCarouselCurrentIndex(state.currentSlide);
                          }}
                        >
                          {packagePhotos?.map((photo) => (
                            <div
                              key={photo.id}
                              className="rounded-t-2xl overflow-hidden"
                            >
                              <img
                                src={photo.url}
                                alt={photo.alt}
                                title={photo.title}
                                className="object-cover w-full aspect-video"
                              />
                            </div>
                          ))}
                        </Carousel>
                        <div className="absolute right-4 bottom-8">
                          <CarouselPagination
                            photos={packagePhotos}
                            currentIndex={carouselCurrentIndex}
                            onSelectPhoto={(index) => {
                              carouselRef?.current?.goToSlide(index);
                            }}
                          />
                        </div>
                      </div>
                      <div className="flex flex-col space-y-4 p-4">
                        <p className="font-[Poppins-SemiBold] text-lg">
                          Services inclus - Offre {selectedPackage?.name}
                        </p>
                        <div className="grid grid-cols-2 md:grid-cols-3 gap-y-6 gap-x-9">
                          {selectedPackage?.options.map((o) => {
                            const option = roomOptionsById[o];
                            return (
                              <div
                                key={`${o}`}
                                className="flex space-x-2 items-center"
                              >
                                <img
                                  src={option.icon}
                                  width={40}
                                  alt="option icon"
                                />
                                <div className="flex flex-col">
                                  <div className="font-[DMSans-Bold]">
                                    {option.name}
                                  </div>
                                  <div className="font-[DMSans-Regular] text-sm leading-4">
                                    {option.description}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </Block>

                    <Block size="s">
                      <div className="flex items-center space-x-2 pb-3">
                        <p className="font-[Poppins-SemiBold] text-lg">
                          Souhaitez-vous une chambre partagée ?
                        </p>
                        <img src={Informations} alt="information" />
                      </div>
                      <Button
                        size="s"
                        id="noPrivateRoom"
                        name="noPrivateRoom"
                        noShadow
                        variant="outline"
                      >
                        Je souhaite une chambre partagée
                      </Button>
                    </Block>

                    {missingOptions.length > 0 && (
                      <Panel title="Services additionnels" className="mt-14">
                        <div className="grid md:grid-cols-2 gap-4">
                          {missingOptions.map((opt) => (
                            <ValidationCheckbox
                              key={opt.id}
                              name="selectedOptions"
                              value={`${opt.id}`}
                              className="border-2 rounded-lg border-yellow-500 bg-[#FFFAEF] p-4"
                            >
                              <div className="flex flex-row items-center flex-1">
                                <div className="flex flex-col flex-1">
                                  <div className="font-[DMSans-Bold] flex flex-row items-baseline gap-x-2">
                                    {opt.name}
                                    {opt.details && (
                                      <div className="w-[18px] h-[18px] border-2 border-gray-900 rounded flex flex-grow-0 flex-shrink-0 items-center justify-center">
                                        i
                                      </div>
                                    )}
                                  </div>
                                  <div className="font-[DMSans-Regular] text-[#5F6C85]">
                                    {opt.description}
                                  </div>
                                </div>
                                <div className="text-center">
                                  <div className="font-[DMSans-Bold] text-sm">
                                    {opt.price}€
                                  </div>
                                  <div className="font-[DMSans-Regular] text-xs">
                                    / jour
                                  </div>
                                </div>
                              </div>
                            </ValidationCheckbox>
                          ))}
                        </div>
                      </Panel>
                    )}
                  </div>
                </div>

                <div className="flex flex-col space-y-6 xl:pl-10 xl:pr-6 w-full shrink-0 md:col-span-3 xl:col-span-2">
                  <Block size="s" className="flex flex-col space-y-3">
                    <div className="flex flex-col justify-center space-y-0">
                      <p className="font-[DMSans-Bold]">Établissement</p>
                      <p className="font-[DMSans-Regular]">
                        Polyclinique Jean Villar
                      </p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="font-[DMSans-Bold]">Arrivée</p>
                      <p className="font-[DMSans-Regular]">30/03/2023</p>
                    </div>

                    <div className="flex items-center space-x-2">
                      <p className="font-[DMSans-Bold]">Sortie *</p>
                      <p className="font-[DMSans-Regular]">02/04/2023</p>
                    </div>

                    <p className="font-[DMSans-Regular] text-xs text-gray-800">
                      *La date de sortie est donnée à titre indicatif. Elle peut
                      varier en fonction de votre hospitalisation
                    </p>
                  </Block>

                  <Block className=" h-fit" size="s">
                    <div className="flex flex-col gap-y-4">
                      <p className="font-[Poppins-SemiBold] text-xl">
                        Estimation
                      </p>

                      <div className="flex flex-col gap-y-4">
                        <div className="flex flex-row items-center justify-between font-[Poppins-SemiBold]">
                          <p className="font-[Poppins-SemiBold] text-md">
                            Chambre {selectedPackage?.name}*
                          </p>
                          <div>
                            {selectedPackage?.pricePerDay}€
                            <span className="font-[DMSans-Regular] text-sm">
                              /j
                            </span>
                          </div>
                        </div>

                        <Collapsible
                          title={`${selectedPackage?.options.length} services compris`}
                          price={0}
                        >
                          <div className="flex flex-col gap-y-2 pl-9">
                            {selectedPackage?.options.map((opt) => {
                              const option = roomOptionsById[opt];

                              return (
                                <div
                                  key={`${option.id}`}
                                  className="flex flex-row items-center gap-x-4"
                                >
                                  <img
                                    src={option.icon}
                                    width={30}
                                    alt="icon"
                                  />
                                  <div className="flex flex-col flex-1">
                                    <div className="font-[DMSans-Bold]">
                                      {option.name}
                                    </div>
                                    <div className="font-[DMSans-Regular] text-sm">
                                      {option.description}
                                    </div>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </Collapsible>

                        <Collapsible
                          title={`${
                            values.selectedOptions?.length ?? 0
                          } services ajoutés`}
                          price={selectedOptionsPrice}
                        >
                          <div className="flex flex-col gap-y-2 pl-9">
                            {selectedOptions.map((option) => (
                              <div
                                key={`${option.id}`}
                                className="flex flex-row items-center gap-x-4"
                              >
                                <img src={option.icon} width={30} alt="icon" />
                                <div className="flex flex-col flex-1">
                                  <div className="font-[DMSans-Bold]">
                                    {option.name}
                                  </div>
                                  <div className="font-[DMSans-Regular] text-sm">
                                    {option.description}
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </Collapsible>

                        <div className="flex flex-col">
                          <div className="flex flex-row items-center font-[Poppins-SemiBold]">
                            <div className="flex flex-1 text-md font-[DMSans-Bold]">
                              Total *
                            </div>
                            <div className="flex flex-shrink-0 items-end flex-grow-0 text-xl">
                              {selectedOptionsPrice +
                                selectedPackage?.pricePerDay}
                              €<span className="text-base">/jour</span>
                            </div>
                          </div>

                          <p className="font-[DMSans-Regular] text-xs text-[#666666]">
                            * sous réserve de la disponibilité de
                            l’établissement
                          </p>
                        </div>
                      </div>
                    </div>
                  </Block>

                  <Block
                    decoration="middleRight"
                    size="s"
                    bgColor="bg-[#F3F3F4]"
                    className="flex items-center space-x-3 "
                  >
                    <img src={HandHoldingMedical} alt="Hand Holding Medical" />
                    <p className="leading-5 pl-2 ">
                      Consultez votre{" "}
                      <span className="font-bold">mutuelle</span> pour connaître
                      le montant de votre prise en charge
                    </p>
                  </Block>

                  {orderedRoomPackages &&
                    initialValues?.packageId > selectedPackage?.id && (
                      <Block
                        size="s"
                        className="flex flex-col py-2 px-2 justify-center items-center space-y-4"
                        bgColor="bg-gradient-to-br from-[#F9B004] to-[#FBCC5C]"
                      >
                        <Button size="s" color="black" className="w-full">
                          Choisir l'offre{" "}
                          {
                            orderedRoomPackages?.[initialValues?.packageId - 1]
                              ?.name
                          }{" "}
                          (+
                          {orderedRoomPackages?.[initialValues?.packageId - 1]
                            ?.pricePerDay -
                            (selectedOptionsPrice +
                              selectedPackage?.pricePerDay)}
                          €/j)
                        </Button>
                        <p className="text-sm">
                          Comprenant{" "}
                          <span className="font-bold">
                            {orderedRoomPackages?.[initialValues?.packageId - 1]
                              ?.options?.length -
                              selectedPackage?.options?.length -
                              selectedOptions.length}{" "}
                            services en plus
                          </span>
                        </p>
                      </Block>
                    )}
                </div>
              </div>
            );
          }}
        </ValidationForm>
      </Container>

      <StickyFooter>
        <div className="flex flex-row items-center justify-between space-x-10 w-full lg:px-20 px-6">
          <Button
            size="s"
            color="white"
            className="flex-shrink-0"
            startIcon={<CaretLeft size={16} />}
            onClick={onPrevious}
          >
            <p>Retour</p>
          </Button>
          <Button
            size="s"
            type="submit"
            form="offerForm"
            className="flex-shrink-0"
            endIcon={<CaretRight size={16} />}
          >
            Réserver ma chambre
          </Button>
        </div>
      </StickyFooter>
    </>
  );
}

export default PackageStep;
