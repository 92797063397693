import { useQuery } from "@tanstack/react-query";

import documentsList from "~/dataSamples/DocumentsList";

export const useGetDocumentsList = (medicalStayId) =>
  useQuery({
    queryKey: ["documentsList"],
    queryFn: () =>
      Promise.resolve(
        documentsList?.filter(
          (document) => document?.medicalStayId === medicalStayId
        )
      ),
    staleTime: 3600 * 24 * 1000, // 1 day
  });
