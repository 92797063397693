import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

function CarouselPagination({
  photos,
  onSelectPhoto,
  currentIndex,
  numberOfThumbnails,
}) {
  let displayedPhotos = photos[currentIndex] ? [photos[currentIndex]] : [];

  let minIndex = currentIndex;
  let maxIndex = currentIndex;

  let openSlots = numberOfThumbnails - 1;
  for (let i = 1; i <= numberOfThumbnails; i++) {
    const downIndex = currentIndex - i;
    const upIndex = currentIndex + i;

    if (openSlots > 0 && downIndex >= 0) {
      displayedPhotos.unshift(photos[downIndex]);
      minIndex = downIndex;
      openSlots--;
    }

    if (openSlots > 0 && upIndex < photos.length) {
      displayedPhotos.push(photos[upIndex]);
      maxIndex = upIndex;
      openSlots--;
    }
  }

  const countLess = Math.abs(0 - minIndex);
  const countMore = photos.length - 1 - maxIndex;

  const activeId = photos[currentIndex]?.id ?? 0;

  return (
    <div className="flex flex-row gap-x-3 items-center">
      {countLess > 0 && <div className="text-white">+{countLess}</div>}
      {displayedPhotos.map((photo) => (
        <div
          key={photo.id}
          className={classNames("cursor-pointer rounded-lg overflow-hidden", {
            "w-11 h-11": activeId !== photo.id,
            "w-12 h-12 border-2 border-white": activeId === photo.id,
          })}
          onClick={() => {
            onSelectPhoto && onSelectPhoto(photos.indexOf(photo));
          }}
        >
          <img
            src={photo.url}
            alt={photo.alt}
            className={classNames("object-cover", {
              "w-11 h-11": activeId !== photo.id,
              "w-12 h-12": activeId === photo.id,
            })}
          />
        </div>
      ))}
      {countMore > 0 && <div className="text-white">+{countMore}</div>}
    </div>
  );
}

CarouselPagination.propTypes = {
  photos: PropTypes.arrayOf(
    PropTypes.shape({
      url: PropTypes.string.isRequired,
    })
  ),
  currentIndex: PropTypes.number.isRequired,
  onSelectPhoto: PropTypes.func,
  numberOfThumbnails: PropTypes.number,
};

CarouselPagination.defaultProps = {
  photos: [],
  currentIndex: 0,
  numberOfThumbnails: 4,
};

export default CarouselPagination;
