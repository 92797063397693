import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Check } from "phosphor-react";

import Button from "~/components/button/Button";
import Modal from "~/components/modal/Modal";
import recapAdminInfoImg from "~/images/recapAdminInfoImg.svg";

const ReviewModal = forwardRef(({ gap }, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
  }));

  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)} size="large">
      <div className="flex flex-col justify-center items-center space-y-6 p-4">
        <div className="flex items-center justify-center w-20 h-20 rounded-full bg-green-50">
          <Check size={58} weight="bold" className="text-green-900" />
        </div>
        <p className="text-center text-lg font-bold leading-6">
          Félicitations !<br />
          Vos informations administratives ont été envoyées à votre clinique
          avec succès
        </p>
        <div className="flex flex-col sm:flex-row gap-4 rounded-xl border shadow-lg p-2">
          <img src={recapAdminInfoImg} alt="recapAdminInfoImg" />
          <div className="flex flex-col justify-between py-2">
            <p className="text-lg font-bold">Souhaits de confort</p>
            <p className="text-sm font-[Poppins-SemiBold]">
              Renseignez dès à présent vos informations d’hospitalité en
              sélectionnant votre chambre et en spécifiant votre régime
              alimentaire.
            </p>
            <div className="mt-4 sm:mt-0 flex flex-row gap-2 items-center">
              <Button
                size="s"
                color="white"
                block
                onClick={() => setIsOpen(false)}
              >
                Plus Tard
              </Button>
              <Button size="s" block>
                Découvrir
              </Button>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
});

export default ReviewModal;
