import React from "react";

import InlineLoader from "~/components/inlineLoader/InlineLoader";

function PageDataLoader({
  isLoading,
  loadingError,
  loadingText = "Chargement",
  children,
}) {
  return isLoading ? (
    <div className="absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center text-gray-500">
      <InlineLoader block xl text={loadingText} />
    </div>
  ) : loadingError ? (
    <div className="text-red-500">Une erreur est survenue : {loadingError}</div>
  ) : children ? (
    children
  ) : null;
}

export default PageDataLoader;
