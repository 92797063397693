import React from "react";

import { Field } from "formik";

import RadioButtonBase from "~/components/forms_unvalidated/radioButton/RadioButton";

function ValidationRadioButton({
  id,
  name,
  value,
  checked,
  label,
  className,
  onCheckedChange,
}) {
  return (
    <Field id={id} type="radio" name={name} value={value} checked={checked}>
      {({ field }) => (
        <RadioButtonBase
          className={className}
          {...field}
          onCheckedChange={(event) => {
            onCheckedChange && onCheckedChange(event.target.checked);
            field?.onChange(event);
          }}
          label={label}
        />
      )}
    </Field>
  );
}

ValidationRadioButton.propTypes = {
  id: RadioButtonBase.propTypes.id,
  name: RadioButtonBase.propTypes.name,
  value: RadioButtonBase.propTypes.value,
  className: RadioButtonBase.propTypes.className,
  checked: RadioButtonBase.propTypes.checked,
  onCheckedChange: RadioButtonBase.propTypes.onCheckedChange,
};

export default ValidationRadioButton;
