import React from "react";

import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";
import { IMaskInput } from "react-imask";

import { getInputClassName } from "../input/Input";
import InputErrorMessage from "../inputErrorMessage/InputErrorMessage";

const FnsDateFormat = "dd/MM/yyyy";

function DatePicker({
  id,
  name,
  min,
  max,
  className,
  touched,
  error,
  value,
  onChange,
  noErrorMessage,
  disabled,
  ...fieldValidationProps
}) {
  return (
    <>
      <ReactDatePicker
        id={id}
        name={name}
        locale="fr"
        dateFormat={FnsDateFormat}
        minDate={min}
        maxDate={max}
        className={`form-input ${getInputClassName(touched, error, className)}`}
        {...fieldValidationProps}
        selected={value ? new Date(value) : null}
        onChange={onChange}
        customInput={<IMaskInput mask={Date} pattern="d`/m`/00000" />}
        disabled={disabled}
      />
      {touched && error && !noErrorMessage && (
        <InputErrorMessage message={error} />
      )}
    </>
  );
}

DatePicker.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  className: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  onChange: PropTypes.func,
  noErrorMessage: PropTypes.bool,
  disabled: PropTypes.bool,
};

DatePicker.defaultProps = {
  className: "",
  touched: false,
  error: null,
  noErrorMessage: false,
  disabled: false,
};

export default DatePicker;
