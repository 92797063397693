import React from "react";

import classNames from "classnames";

function FormGroup({ className = "", children }) {
  return (
    <div className={classNames("flex flex-col flex-1 space-y-3", className)}>
      {children}
    </div>
  );
}

export default FormGroup;
