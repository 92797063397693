import React from "react";

import classNames from "classnames";

import ElsanLogo from "~/components/elsanLogo/ElsanLogo";
import StringHelper from "~/helpers/StringHelper";

function InlineLoader({ text = "Chargement", block = false, xl = false }) {
  return (
    <div
      className={classNames("flex items-center justify-center", {
        "flex-col": block,
        "flex-row": !block,
      })}
    >
      <div
        className={classNames(
          "fill-yellow-500 h-6 flex justify-center animate-pulse",
          {
            "items-baseline": block,
            "me-2 mr-4": !block,
            "mb-8": xl,
          }
        )}
      >
        <ElsanLogo className={classNames({ "h-12": xl, "h-6": !xl })} />
      </div>
      {!StringHelper.isNullOrEmpty(text) && <div>{text}</div>}
    </div>
  );
}

export default InlineLoader;
