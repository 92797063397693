import { useQuery } from "@tanstack/react-query";

import roomOptions from "~/dataSamples/RoomOptions";
import roomPackages from "~/dataSamples/RoomPackages";
import RoomPhotos from "~/dataSamples/RoomPhotos";

export const getRoomOptions = {
  queryKey: ["roomOptions"],
  queryFn: () => Promise.resolve(roomOptions),
  staleTime: 3600 * 24 * 1000, // 1 day
};

export const getRoomPackages = {
  queryKey: ["roomPackages"],
  queryFn: () => Promise.resolve(roomPackages),
  staleTime: 3600 * 2 * 1000, // 2 hours
};

export const getRoomPhotos = {
  queryKey: ["roomImages"],
  queryFn: () => Promise.resolve(RoomPhotos),
  staleTime: 3600 * 2 * 1000, // 2 hours
};

export const useGetRoomOptions = () => useQuery(getRoomOptions);

export const useGetRoomPackages = () => useQuery(getRoomPackages);

export const useGetRoomPhotos = () => useQuery(getRoomPhotos);
