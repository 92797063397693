import { useContext, createContext, useState, useCallback } from "react";

const layoutContext = createContext();

export function ProvideLayout({ children }) {
  const layout = useProvideLayout();

  return (
    <layoutContext.Provider value={layout}>{children}</layoutContext.Provider>
  );
}

const useLayout = () => {
  return useContext(layoutContext);
};

export default useLayout;

const defaultState = {
  title: "Elsan Care",
  type: "default",
  bkgImage: "default",
  clinic: null,
};

function useProvideLayout() {
  const [layout, setLayoutData] = useState(defaultState);

  const setLayout = useCallback(
    (data) => {
      setLayoutData({
        ...defaultState,
        ...data,
      });
    },
    [setLayoutData]
  );

  console.log("layout", layout);
  // Return the user object and layout methods
  return { layout, setLayout };
}
