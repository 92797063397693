import React from "react";

import { Field } from "formik";

import SocialSecurityNumberInputBase from "~/components/forms_unvalidated/socialSecurityNumberInput/SocialSecurityNumberInput";

function ValidationSocialSecurityNumberInput({
  id,
  name,
  className,
  onChange,
  noErrorMessage,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <SocialSecurityNumberInputBase
              id={id}
              className={className}
              {...field}
              touched={meta.touched}
              error={meta.error}
              value={meta.value ?? ""}
              onChange={(value) => {
                onChange && onChange(value, form);
                form.setFieldValue(name, value ?? "");
              }}
              noErrorMessage={noErrorMessage}
            />
          );
        }}
      </Field>
    </>
  );
}

ValidationSocialSecurityNumberInput.propTypes = {
  id: SocialSecurityNumberInputBase.propTypes.id,
  name: SocialSecurityNumberInputBase.propTypes.name,
  className: SocialSecurityNumberInputBase.propTypes.className,
  onChange: SocialSecurityNumberInputBase.propTypes.onChange,
  autoComplete: SocialSecurityNumberInputBase.propTypes.autoComplete,
  noErrorMessage: SocialSecurityNumberInputBase.propTypes.noErrorMessage,
};

ValidationSocialSecurityNumberInput.defaultProps = {
  value: SocialSecurityNumberInputBase.defaultProps.value,
  className: SocialSecurityNumberInputBase.defaultProps.className,
  noErrorMessage: SocialSecurityNumberInputBase.defaultProps.noErrorMessage,
};

export default ValidationSocialSecurityNumberInput;
