import React from "react";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import ScrollToTop from "~/components/scrollToTop/ScrollToTop.js";
import LeftColumnLayout from "~/layouts/LeftColumnLayout";
import PageLayout from "~/layouts/PageLayout";
import Dashboard from "~/pages/dashboard/Dashboard";
import Error404 from "~/pages/error404/Error404";
import HomePage from "~/pages/homePage/HomePage";
import MedicalStayPage from "~/pages/medicalStay/MedicalStayPage";
import PreadmissionPage from "~/pages/preadmission/PreadmissionPage";
import MyProfile from "~/pages/profile/MyProfile";

const pageLayoutRoutes = [
  {
    key: "myProfile",
    path: "myProfile",
    exact: true,
    component: MyProfile,
  },
  {
    key: "patientDashboard",
    path: "dashboard",
    exact: true,
    component: Dashboard,
  },
  {
    key: "medicalStayDetails",
    path: "medicalStays/:serialNumber",
    exact: true,
    component: MedicalStayPage,
  },
  {
    key: "pread",
    path: "medicalStays/:serialNumber/preadmission",
    exact: true,
    component: PreadmissionPage,
  },
];

const leftColumnLayoutRoutes = [
  { key: "home", path: "", exact: true, component: HomePage },
  { key: "404", path: "*", exact: true, component: Error404 },
];

export default function Router() {
  const renderRoute = (route, prefix = null) => {
    let Component = route.component;

    return (
      <Route
        key={`${prefix || "*"}/${route.path}`}
        path={`${route.path}`}
        element={<Component />}
      />
    );
  };

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="*" element={<PageLayout />}>
          {pageLayoutRoutes.map((route) => renderRoute(route))}
        </Route>

        <Route path="*" element={<LeftColumnLayout />}>
          {leftColumnLayoutRoutes.map((r) => renderRoute(r))}
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
