import React, { forwardRef, useImperativeHandle, useState } from "react";

import { Formik, Form } from "formik";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import Button from "~/components/button/Button";
import FormGroup from "~/components/forms/formGroup/FormGroup";
import ValidationInput from "~/components/forms/validationInput/ValidationInput";
import Modal from "~/components/modal/Modal";

const SigemsValidationSchema = Yup.object().shape({
  ipp: Yup.string().required("L'IPP est requis"),
});

const Web100TValidationSchema = Yup.object().shape({
  guid: Yup.string().required("Le GUID du patient est requis"),
});

const GAPModal = forwardRef(({ gap }, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const navigate = useNavigate();

  useImperativeHandle(ref, () => ({
    open: () => setIsOpen(true),
  }));

  return (
    <Modal
      isOpen={isOpen}
      onClose={() => setIsOpen(false)}
      title={gap === "WEB100T" ? "GUID du patient" : "IPP"}
    >
      <Formik
        initialValues={
          gap === "WEB100T"
            ? {
                guid: "",
              }
            : {
                ipp: "",
              }
        }
        validationSchema={
          gap === "WEB100T" ? Web100TValidationSchema : SigemsValidationSchema
        }
        onSubmit={(values) => {
          navigate(
            `/medicalStays/${gap}/${
              gap === "WEB100T" ? values.guid : values.ipp
            }/pread/dashboardMulti`
          );
        }}
      >
        <Form method="post">
          <FormGroup className="my-4">
            {gap === "WEB100T" ? (
              <ValidationInput name="guid" placeholder="Guid" />
            ) : (
              <ValidationInput name="ipp" placeholder="ipp" />
            )}
          </FormGroup>

          <div className="mt-4 flex flex-row items-center justify-between">
            <Button color="transparent" onClick={() => setIsOpen(false)}>
              Annuler
            </Button>
            <Button type="submit">Valider</Button>
          </div>
        </Form>
      </Formik>
    </Modal>
  );
});

export default GAPModal;
