const medicalStayOrga = [
  {
    interventionId: 1,
    dayBeforeOrAfterMedicalStay: -1,
    title: "Veille de votre hospitalisation",
    actions: [
      { title: "1ère douche pré-opératoire", link: "/" },
      { title: "Coupe des ongles des pieds et des mains", link: "/" },
      {
        title: "Re-lire le livret d'accueil et les consignes pré-opératoires",
        link: "/",
      },
      { title: "Répondre au questionnaire pré-opératoire", link: "/" },
    ],
  },
  {
    interventionId: 1,
    dayBeforeOrAfterMedicalStay: 0,
    title: "Jour de votre hospitalisation",
    actions: [
      { title: "2ème douche pré-opératoire", link: "/" },
      { title: "préparer ses documents pour l'admission", link: "/" },
      { title: "venir 3h avant l'opération", link: "/" },
    ],
  },
  {
    interventionId: 1,
    dayBeforeOrAfterMedicalStay: 1,
    title: "Lendemain de votre hospitalisation",
    actions: [
      { title: "Répondre au questionnaire post opératoire", link: "/" },
    ],
  },
  {
    interventionId: 2,
    dayBeforeOrAfterMedicalStay: -1,
    title: "Veille de votre hospitalisation",
    actions: [
      { title: "1ère douche pré-opératoire", link: "/" },
      { title: "Coupe des ongles des pieds et des mains", link: "/" },
      {
        title: "Re-lire le livret d'accueil et les consignes pré-opératoires",
        link: "/",
      },
      { title: "Répondre au questionnaire pré-opératoire", link: "/" },
    ],
  },
  {
    interventionId: 2,
    dayBeforeOrAfterMedicalStay: 0,
    title: "Jour de votre hospitalisation",
    actions: [
      { title: "2ème douche pré-opératoire", link: "/" },
      { title: "préparer ses documents pour l'admission", link: "/" },
      { title: "venir 3h avant l'opération", link: "/" },
    ],
  },
  {
    interventionId: 2,
    dayBeforeOrAfterMedicalStay: 1,
    title: "Lendemain de votre hospitalisation",
    actions: [
      { title: "Répondre au questionnaire post opératoire", link: "/" },
    ],
  },
];

export default medicalStayOrga;
