import React from "react";

export default function Error404() {
  return (
    <div className="text-2xl flex flex-col justify-center items-center">
      <div>Oups !</div>
      <h1 className="text-6xl my-6">404</h1>
      <div>La page que vous recherchez n'existe pas</div>
    </div>
  );
}
