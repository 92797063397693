import React from "react";

import { Field } from "formik";

import InputBase from "~/components/forms_unvalidated/input/Input";

function ValidationInput({
  id,
  name,
  type,
  placeholder,
  onChange,
  autoComplete,
  noErrorMessage,
  maxLength,
  readOnly,
  disabled,
}) {
  return (
    <>
      <Field name={name}>
        {({ field, form, meta }) => {
          return (
            <InputBase
              id={id}
              type={type}
              placeholder={placeholder}
              autoComplete={autoComplete}
              readOnly={readOnly}
              disabled={disabled}
              {...field}
              maxLength={maxLength}
              touched={meta.touched}
              error={meta.error}
              value={meta.value ?? ""}
              onChange={(e) => {
                field.onChange(e);
                onChange && onChange(e, form);
              }}
              noErrorMessage={noErrorMessage}
            />
          );
        }}
      </Field>
    </>
  );
}

ValidationInput.propTypes = {
  name: InputBase.propTypes.name.isRequired,
  type: InputBase.propTypes.type,
  className: InputBase.propTypes.className,
  placeholder: InputBase.propTypes.placeholder,
  onChange: InputBase.propTypes.onChange,
  autoComplete: InputBase.propTypes.autoComplete,
  noErrorMessage: InputBase.propTypes.noErrorMessage,
  maxLength: InputBase.propTypes.maxLength,
  readOnly: InputBase.propTypes.readOnly,
  disabled: InputBase.propTypes.disabled,
};

ValidationInput.defaultProps = {
  type: InputBase.defaultProps.type,
  className: InputBase.defaultProps.className,
  placeholder: InputBase.defaultProps.string,
  autoComplete: InputBase.defaultProps.autoComplete,
  noErrorMessage: InputBase.defaultProps.noErrorMessage,
  readOnly: InputBase.defaultProps.readOnly,
  disabled: InputBase.defaultProps.disabled,
};

export default ValidationInput;
