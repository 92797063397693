import React from "react";

import { faLocation, faUserDoctor } from "@fortawesome/free-solid-svg-icons";

import moment from "moment";
import "moment/locale/fr";
import { CalendarBlank, Eye, Plus } from "phosphor-react";

import Block from "~/components/block/Block";
import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import { useGetDocumentsList } from "~/queries/documentsListQueries";
import { useGetTodoPreOp } from "~/queries/todoPreOpQueries";
import Circular from "~/images/decorations/Circular.svg";
import divertissement from "~/images/divertissement.png";
import InfoIllustration from "~/images/illustrations/info.png";
import ScanIllustration from "~/images/illustrations/scan.png";
import ScanIllustrationMobile from "~/images/illustrations/scanMobile.png";
import meal from "~/images/meal.png";
import Douche from "~/images/photos/douche.png";
import Qrcode from "~/images/qrcode.png";
import Carousel from "react-multi-carousel";
import CarouselDot from "~/components/carousel/CarouselDot";
import CarouselArrows from "../../../components/carousel/CarouselArrows";
import IconCircle from "~/components/iconCircle/IconCircle";
import ToDoCarouselItem from "./ToDoCarouselItem";
import PageDataLoader from "~/components/pageDataLoader/PageDataLoader";
import { useGetMedicalStayOrga } from "~/queries/medicalStayOrgaQueries";
import InlineDocument from "~/components/inlineDocument/InlineDocument";

const carouselBreakpoints = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    partialVisibilityGutter: 40,
  },
  tablet: {
    breakpoint: { max: 1024, min: 768 },
    items: 2,
    partialVisibilityGutter: 40,
  },
  mobile: {
    breakpoint: { max: 768, min: 0 },
    items: 1,
    partialVisibilityGutter: 40,
  },
};

function MedicalStayPreOp({ medicalStay }) {
  moment.locale("fr");

  const { isLoading: toDoPreOpLoading, data: todoPreOp } = useGetTodoPreOp();

  const { isLoading: documentsListLoading, data: documentsList } =
    useGetDocumentsList(medicalStay.id);

  const dayBeforeMedicalStay = moment(medicalStay?.startDate)?.diff(
    moment(),
    "days"
  );

  const { isLoading: medicalStayOrgaLoading, data: medicalStayOrga } =
    useGetMedicalStayOrga(medicalStay?.interventionId);

  return (
    <PageDataLoader
      isLoading={
        toDoPreOpLoading || documentsListLoading || medicalStayOrgaLoading
      }
    >
      <div
        className="absolute top-0 left-0 w-full md:h-[404px] h-[757px] flex justify-center
                      bg-gradient-to-bl from-[#F9B004] to-[#FBCC5C] mt-14 md:mt-8"
      >
        <Container className="h-full mt-14">
          <div className="flex items-start justify-center 2xl:justify-between h-full">
            <div className="flex flex-1 justify-center">
              <img
                src={Circular}
                alt="Circular"
                className="xl:flex hidden w-28 mr-16 mt-5"
              />
            </div>
            <div className="xl:w-[532px] xl:mt-0  lg:w-[440px] w-80 lg:mt-20 mt-20 ">
              <img
                src={ScanIllustration}
                alt="scanIllustration"
                className="shrink-0 object-contain  hidden md:flex p-6"
              />
            </div>
          </div>
        </Container>
      </div>
      <div className="absolute md:top-[404px] top-[757px] left-0 bg-[#FFFBF3] h-56 mt-14 md:mt-8 w-full" />

      <Container className="flex flex-col space-y-8 relative overflow-hidden mt-20 ">
        <div className="flex flex-col lg:space-x-8 lg:flex-row gap-y-2 md:items-center mt-10 md:mt-16">
          <p className="text-3xl md:text-4xl font-[Poppins-Bold]">
            Votre hospitalisation
          </p>
          <div className="flex order-first md:order-last">
            <div className=" flex space-x-2 items-center rounded-full bg-[#D5DCF5] px-5 py-2 text-sm font-semibold">
              <CalendarBlank size={24} weight="bold" />
              <p className="text-base ">Jour - {dayBeforeMedicalStay}</p>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 items-end gap-x-10 gap-y-8 w-full ">
          <div className="relative flex flex-col items-center gap-y-8 justify-center w-full md:hidden">
            <div className="absolute left-0 top-16 -m-4">
              <img
                src={ScanIllustrationMobile}
                alt="scanIllustration"
                className="shrink-0 object-contain h-56"
              />
            </div>
            <div className="bg-white rounded-xl w-44 h-44 z-10">
              <img src={Qrcode} alt="Qrcode" className="w-44" />
            </div>
            <Block
              size="s"
              className="flex flex-1 flex-col w-full gap-y-3 px-2 z-10"
            >
              <p>
                <span className="font-bold">Scannez ce QR code</span> à votre
                arrivée à l’hôpital afin de vous enregistrer
              </p>
              <Button size="s" color="black">
                <p className="text-base">Agrandir le QR code</p>
              </Button>
            </Block>
          </div>
          <Block
            size="s"
            className="flex flex-1 flex-col gap-y-2 md:gap-y-6 w-full p-2"
          >
            <div className="hidden md:flex flex-col space-y-3">
              <p className="font-[Poppins-SemiBold]">
                Votre hospitalisation aura lieu dans {dayBeforeMedicalStay}{" "}
                jours
              </p>
              <p className="leading-4 text-sm">
                Retrouvez ci-dessous les informations relatives à votre séjours.{" "}
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-y-0 gap-y-2 gap-x-3 text-sm">
              <div className="flex flex-row md:flex-col gap-y-1 gap-x-2 items-center md:items-start">
                <p className="font-[Poppins-SemiBold]">Arrivée</p>
                <p className="leading-4">
                  {moment(medicalStay?.startDate).format("DD/MM/YYYY")} -{" "}
                  {moment(medicalStay?.startDate).format("HH:mm")}
                </p>
              </div>
              <div className="flex flex-row md:flex-col gap-y-1 gap-x-2  items-center md:items-start">
                <p className="font-[Poppins-SemiBold]">Sortie prévue</p>
                <p className="leading-4">
                  {moment(medicalStay?.endDate).format("DD/MM/YYYY")} - ND
                </p>
              </div>
            </div>

            <hr />

            <div className="grid grid-cols-1 md:grid-cols-2 md:gap-y-0 gap-y-2 gap-x-3 text-sm">
              <div className="flex flex-row md:flex-col gap-y-1 gap-x-2 items-center md:items-start">
                <p className="font-[Poppins-SemiBold]">Etablissement</p>
                <p className="leading-4">{medicalStay?.location}</p>
              </div>
              <div className="flex flex-row md:flex-col gap-y-1 gap-x-2 items-center md:items-start">
                <p className="font-[Poppins-SemiBold]">Service</p>

                <p className="leading-4 first-letter:uppercase">
                  {medicalStay.intervention}
                </p>
              </div>
            </div>
          </Block>
          <div className="hidden md:flex">
            <Block
              size="s"
              className="flex flex-1 flex-col lg:flex-row justify-between  space-x-4 space-y-4 lg:space-y-0 items-center w-full"
            >
              <img src={Qrcode} alt="Qrcode" className="h-16" />
              <div className="flex flex-col space-y-1">
                <p className="font-bold">Scannez ce QR code</p>
                <p className="leading-4">
                  à votre arrivée à l’hôpital afin de vous enregistrer
                </p>
              </div>
              <Button size="s" color="black">
                Afficher
              </Button>
            </Block>
          </div>
        </div>
      </Container>

      <div className="flex justify-center bg-[#FFFBF3] w-full pt-8 mb-8">
        <Container className="relative flex flex-col pt-8 md:pt-0">
          <p className="text-3xl font-[Poppins-SemiBold] ">
            À faire avant votre venue
          </p>
          <Carousel
            swipeable
            arrows={false}
            showDots
            customDot={<CarouselDot />}
            responsive={carouselBreakpoints}
            keyBoardControl
            renderButtonGroupOutside
            customButtonGroup={<CarouselArrows />}
            containerClass="my-8 pb-10"
            dotListClass="w-1/2 mx-auto"
            itemClass="px-4 flex self-stretch"
          >
            {todoPreOp?.map((todo, j) => (
              <ToDoCarouselItem
                key={`todo ${j}`}
                title={todo.title}
                subTitle={todo.subTitle}
                icon={todo.icon}
                link={todo.link}
                decoration={todo.decoration}
              />
            ))}
          </Carousel>
        </Container>
      </div>

      <Container>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <Block size="s" className="flex flex-col space-y-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <p className="font-[Poppins-SemiBold] text-xl p-2">
                Déroulé de votre séjour
              </p>
              <Button size="s" color="black">
                Voir tout
              </Button>
            </div>
            <hr />

            <div className="flex flex-col max-h-80 overflow-auto pr-2">
              {medicalStayOrga?.map((medicalStayDay, indexDay) => (
                <>
                  <div
                    key={`medicalStayDay ${indexDay}`}
                    className="bg-[#F3F3F4] rounded-2xl px-8 pt-4 flex flex-col"
                  >
                    <p className="text-sm text-[#3F444D] font-[DMSans-Regular]">
                      {moment(medicalStay?.startDate)
                        .add(
                          medicalStayDay?.dayBeforeOrAfterMedicalStay,
                          "days"
                        )

                        .format("LL")}
                    </p>
                    <p className="font-semibold">{medicalStayDay?.title}</p>
                  </div>
                  <ol class="border-l-[2px] border-[#F8F7F6] mx-8">
                    {medicalStayDay?.actions?.map((action, indexAction) => (
                      <li key={`action ${indexAction}`}>
                        <div class="flex flex-start items-center pt-3">
                          <div class="bg-yellow-500 h-2 w-2 rotate-45 -ml-[5px] mr-4" />
                          <p className="">{action.title}</p>
                        </div>
                      </li>
                    ))}
                  </ol>
                </>
              ))}
            </div>
          </Block>
          <Block size="s" className="flex flex-col space-y-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <p className="font-[Poppins-SemiBold] text-xl p-2">
                Vos derniers documents
              </p>
              <Button size="s" color="black">
                Voir tout
              </Button>
            </div>
            <hr />

            <div className="flex flex-col max-h-80 overflow-auto gap-y-4">
              {documentsList?.map((document, index) => (
                <InlineDocument
                  key={`document ${index}`}
                  document={document}
                  size="xl"
                />
              ))}
            </div>
          </Block>
        </div>
      </Container>

      <div
        className="flex justify-center w-full bg-[#F9FAFB] 
        bg-gradient-to-br from-[#344A8E] to-[#215BBE] mt-8"
      >
        <Container className="relative grid grid-cols-1 lg:grid-cols-3 gap-x-10 my-8  w-full">
          <img src={InfoIllustration} alt="InfoIllustration" />
          <div className="flex flex-col space-y-6 w-full col-span-2">
            <p className="text-2xl md:text-3xl font-[Poppins-SemiBold] text-white">
              Informations complémentaires
            </p>
            <div className="flex justify-between items-center  rounded-2xl space-x-4 px-6 py-3 bg-white w-full">
              <div className="flex items-center space-x-4">
                <IconCircle icon={faLocation} size="lg" />
                <div className="flex flex-col">
                  <p className="font-bold">Votre adresse</p>
                  <p>{medicalStay?.address}</p>
                </div>
              </div>
              <Button size="s" color="black" className="shrink-0">
                M'y rendre
              </Button>
            </div>
            <div className="flex justify-between items-center  rounded-2xl space-x-4 px-6 py-3 bg-white w-full">
              <div className="flex items-center space-x-4">
                <IconCircle icon={faUserDoctor} size="lg" />
                <div className="flex flex-col ">
                  <p className="font-bold">Votre praticien</p>
                  <p>{medicalStay?.practitioner}</p>
                </div>
              </div>
              <Button size="s" color="black" className="shrink-0">
                M'y rendre
              </Button>
            </div>
          </div>
        </Container>
      </div>

      <Container className="mt-8">
        <Block size="s" className="grid grid-cols-1 lg:grid-cols-3 gap-x-4">
          <img
            src={Douche}
            alt="Douche"
            className="h-full shrink-0 object-contain"
          />
          <div className="col-span-2 flex flex-col space-y-4 w-full">
            <div className="flex">
              <p className=" text-sm px-2 bg-[#5674D7] rounded-2xl text-white font-[Poppins-SemiBold]">
                Douche pré-opératoire
              </p>
            </div>
            <p className="xl:text-xl lg:text-lg font-[Poppins-SemiBold]">
              A quel moment de la journée faire la douche pré-opératoire ?
            </p>
            <p className="lg:text-base text-sm">
              Lorem ipsum dolor sit amet consectetur. Tempor ut tellus mattis
              vitae. Pharetra mi praesent amet leo.
            </p>
            <div className="flex">
              <Button size="s" color="gradiant" variant="outline">
                Découvrir
              </Button>
            </div>
          </div>
        </Block>
      </Container>

      <div className="bg-white w-full flex justify-center mt-10 pt-10 pb-20">
        <Container className=" flex flex-col space-y-10 ">
          <p className="text-3xl font-[Poppins-SemiBold]">
            Pour plus de confort
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 col ">
            <Block
              size="s"
              className="flex flex-col items-start  gap-y-8 h-full"
            >
              <img
                src={meal}
                className="self-center rounded-xl w-full h-60"
                alt="meal"
              />
              <div className="flex xl:flex-row flex-col justify-between w-full gap-y-4 h-full">
                <div className="flex flex-1 flex-col">
                  <p className="text-xl font-semibold">Nos repas à la carte</p>

                  <p className="text-lg font-[DMSans-Regular]">
                    Choisissez parmi notre sélection de plats
                  </p>
                </div>
                <div className="flex justify-center items-center shrink-0 mb-2">
                  <Button
                    size="s"
                    variant="outline"
                    startIcon={<Plus size={22} weight="bold" />}
                  >
                    En savoir plus
                  </Button>
                </div>
              </div>
            </Block>

            <Block
              size="s"
              className="flex flex-col items-start gap-y-8 h-full"
            >
              <img
                src={divertissement}
                className="self-center rounded-xl w-full h-60"
                alt="divertissement"
              />
              <div className="flex xl:flex-row flex-col justify-between w-full gap-y-4 h-full">
                <div className="flex flex-col">
                  <p className="text-xl font-semibold">
                    Divertissements et activités
                  </p>

                  <p className="text-lg font-[DMSans-Regular]">
                    Découvrez notre bibliothèque de livres, magazines, bouquet
                    TV
                  </p>
                </div>
                <div className="flex justify-center items-center shrink-0 mb-2">
                  <Button
                    size="s"
                    variant="outline"
                    startIcon={<Plus size={22} weight="bold" />}
                  >
                    En savoir plus
                  </Button>
                </div>
              </div>
            </Block>
          </div>
        </Container>
      </div>
    </PageDataLoader>
  );
}

export default MedicalStayPreOp;
