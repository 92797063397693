import React from "react";
import Container from "~/components/container/Container";

function StickyFooter({ children }) {
  return (
    <div
      className="fixed flex justify-center items-center w-full bottom-0 left-0 bg-white h-20 text-md
                     shadow-[0_-6px_6px_-4px_rgba(0,0,0,0.15)]"
    >
      <Container>{children}</Container>
    </div>
  );
}

export default StickyFooter;
