import React, { useEffect } from "react";

import { CaretRight } from "phosphor-react";
import * as Yup from "yup";

import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import FormGroup from "~/components/forms/formGroup/FormGroup";
import Label from "~/components/forms/label/Label";
import ValidationCheckbox from "~/components/forms/validationCheckbox/ValidationCheckbox";
import ValidationDatePicker from "~/components/forms/validationDatePicker/ValidationDatePicker";
import ValidationFileInput from "~/components/forms/validationFileInput/ValidationFileInput";
import ValidationForm from "~/components/forms/validationForm/ValidationForm";
import ValidationInput from "~/components/forms/validationInput/ValidationInput";
import ValidationPhoneNumberInput from "~/components/forms/validationPhoneNumberInput/ValidationPhoneNumberInput";
import ValidationRadioButton from "~/components/forms/validationRadioButton/ValidationRadioButton";
import ValidationRadioButtonsGroup from "~/components/forms/validationRadioButtonsGroup/ValidationRadioButtonsGroup";
import ValidationSocialSecurityNumberInput from "~/components/forms/validationSocialSecurityNumberInput/ValidationSocialSecurityNumberInput";

import { Plus } from "phosphor-react";

import Panel from "~/components/panel/Panel";
import usePread from "~/contexts/usePread";
import Identity from "~/images/icons/identity.svg";
import AddressBook from "~/images/icons/address-book.svg";
import Documents from "~/images/icons/documents.svg";

import FormID from "~/images/illustrations/formID.png";

import StickyFooter from "~/pages/preadmission/components/stickyFooter";
import { useSavePatientStep } from "~/queries/preadQueries";
import { useNavigate } from "react-router-dom";

const FormValidationSchema = Yup.object().shape({
  firstName: Yup.string().required("Le prénom est requis"),
  lastName: Yup.string().required("Le nom est requis"),
  birthDate: Yup.date("La date de naissance requise")
    .nullable("Date requise")
    .min(
      new Date(1900, 0, 1),
      "La date de naissance doit être supérieure au 1er janvier 1900"
    )
    .required("Champs requis"),
  email: Yup.string()
    .email("Adresse email invalide")
    .required("Un email est requis"),
  address: Yup.string().required("Une adresse est requise"),
  postCode: Yup.string()
    .required("Le code postal est requis")
    .min(5, "Le code postal doit contenir au moins 5 caractères"),
  city: Yup.string().required("La ville est requise"),
  mobilePhone: Yup.string()
    .matches(
      /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
      "Le téléphone n'est pas au bon format"
    )
    .required("Le téléphone est requis")
    .min(8, "Le numéro de téléphone doit contenir au moins 8 chiffres"),
});

export const patientStepPageParameters = {
  title: "Vos informations administratives",
  subTitle: null,
  image: FormID,
  imageTitle: "Informations administratives",
};

function PatientStep({ onSubmitted, onPrevious }) {
  const navigate = useNavigate();
  const { medicalStay, config, savePatientData } = usePread();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = useSavePatientStep((data, variables, context) => {
    console.log("saved");
    savePatientData(variables);
    onSubmitted();
  });

  return medicalStay && config ? (
    <>
      <Container className="mb-32">
        <ValidationForm
          id="patientForm"
          initialValues={{
            sex: medicalStay.patient.sex,
            firstName: medicalStay.patient.firstName,
            lastName: medicalStay.patient.lastName,
            birthName: medicalStay.patient.birthName,
            birthDate: medicalStay.patient.birthDate,
            email: medicalStay.patient.email,
            address: medicalStay.patient.address,
            postCode: medicalStay.patient.postCode,
            city: medicalStay.patient.city,
            mobilePhone: medicalStay.patient.mobilePhone,
            identityDocument: "idCard",
            socialSecurityPatientName: `${medicalStay.patient.firstName} ${medicalStay.patient.lastName}`,
            socialSecurityBirthDate: medicalStay.patient.birthDate,
          }}
          validationSchema={FormValidationSchema}
          onSubmit={(formData, { setSubmitting }) => {
            handleSubmit.mutate(formData);
            setSubmitting(false);
          }}
        >
          {(formik) => {
            const { values } = formik;
            return (
              <div className="flex flex-col space-y-12 w-full">
                <Panel
                  title="Identité"
                  subtitle="Vérifiez ci-dessous si les informations sont correctes. Elles sont non modifiables mais en cas d’anomalie, vous pouvez nous les indiquer dans l’encart “Préciser l’anomalie”"
                  icon={<img src={Identity} alt="Carte d'identité" />}
                >
                  <div className="flex flex-col space-y-8">
                    <div className="gap-y-3 flex flex-col">
                      <Label htmlFor="birthName">Genre</Label>
                      <div>
                        <ValidationRadioButtonsGroup
                          id="sex"
                          name="sex"
                          values={config.sexes}
                        />
                      </div>
                    </div>

                    <div className="grid sm:grid-cols-2 gap-6">
                      <FormGroup>
                        <Label htmlFor="birthName">Nom de naissance</Label>
                        <ValidationInput
                          id="birthName"
                          name="birthName"
                          placeholder="Nom de naissance"
                          disabled
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="lastName">Nom usuel</Label>
                        <ValidationInput
                          id="lastName"
                          name="lastName"
                          placeholder="Nom usuel"
                          disabled
                        />
                      </FormGroup>
                    </div>

                    <div className="grid sm:grid-cols-2 gap-6">
                      <FormGroup>
                        <Label htmlFor="firstName">Prénom</Label>
                        <ValidationInput
                          id="firstName"
                          name="firstName"
                          placeholder="Prénom"
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="birthDate">Date de naissance</Label>
                        <ValidationDatePicker
                          id="birthDate"
                          name="birthDate"
                          placeholder="JJ/MM/AAAA"
                          disabled
                        />
                      </FormGroup>
                    </div>

                    <ValidationCheckbox id="identityError" name="identityError">
                      Je souhaite remonter une anomalie sur mes informations
                      d'identité
                    </ValidationCheckbox>

                    {values.identityError && (
                      <FormGroup className="mt-3">
                        <Label htmlFor="identityErrorDescription">
                          Précisez l'anomalie
                        </Label>
                        <ValidationInput
                          id="identityErrorDescription"
                          name="identityErrorDescription"
                        />
                      </FormGroup>
                    )}
                  </div>
                </Panel>

                <Panel
                  title="Coordonnées"
                  subtitle="Nous affichons les informations indiquées à votre établissement. Vous pouvez les mettre à jour directement dans les encarts prévus à cet effet"
                  icon={<img src={AddressBook} alt="AddressBook" />}
                >
                  <div className="flex flex-col space-y-8">
                    <FormGroup className="">
                      <Label htmlFor="address">Adresse</Label>
                      <ValidationInput
                        id="address"
                        name="address"
                        placeholder="Adresse"
                      />
                    </FormGroup>

                    <div className="grid sm:grid-cols-2 gap-6">
                      <FormGroup>
                        <Label htmlFor="postCode">Code postal</Label>
                        <ValidationInput
                          id="postCode"
                          name="postCode"
                          placeholder="Code postal"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="city">Ville</Label>
                        <ValidationInput
                          id="city"
                          name="city"
                          placeholder="Ville"
                        />
                      </FormGroup>
                    </div>

                    <div className="grid sm:grid-cols-2 gap-6">
                      <FormGroup>
                        <Label htmlFor="email">Adresse email</Label>
                        <ValidationInput
                          id="email"
                          name="email"
                          placeholder="Adresse email"
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="mobilePhone">Téléphone mobile</Label>
                        <ValidationPhoneNumberInput
                          id="mobilePhone"
                          name="mobilePhone"
                          placeholder="06XXXXXXXX"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Panel>

                <Panel
                  title="Documents"
                  subtitle="Ajoutez vos documents complémentaires pour une prise en charge optimale"
                  icon={<img src={Documents} alt="Documents" />}
                >
                  <div className="flex flex-col space-y-6 border-b-2 border-gray-300 pb-12">
                    <div className="flex flex-col space-y-1">
                      <p className="font-[Poppins-SemiBold] text-lg">
                        Justificatif d’identité
                      </p>
                      <p>
                        Ajoutez votre carte d’identité ou votre passeport en
                        cours de validité
                      </p>
                    </div>

                    <div className="flex flex-row items-center gap-x-8">
                      <ValidationRadioButton
                        name="identityDocument"
                        value="idCard"
                        label="Carte d'identité nationale"
                      />
                      <ValidationRadioButton
                        name="identityDocument"
                        value="passport"
                        label="Passeport"
                      />
                    </div>

                    <div className="mt-4">
                      {values.identityDocument === "idCard" && (
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-8">
                          <ValidationFileInput
                            name="idCardFront"
                            instructions="Glisser le recto"
                            description="Recto"
                          />
                          <ValidationFileInput
                            name="idCardBack"
                            instructions="Glisser le verso"
                            description="Verso"
                          />
                        </div>
                      )}
                      {values.identityDocument === "passport" && (
                        <ValidationFileInput
                          name="passport"
                          description="Passeport"
                        />
                      )}
                    </div>

                    <div className="flex w-full justify-center items-center">
                      <Button
                        size="s"
                        color="yellow"
                        variant="outline"
                        startIcon={<Plus size={18} />}
                      >
                        <p>Comment ajouter une pièce d’identité ?</p>
                      </Button>
                    </div>
                  </div>

                  <div className="flex flex-col space-y-6 border-b-2 border-gray-300 pt-6 pb-12">
                    <div className="flex flex-col space-y-1">
                      <p className="font-[Poppins-SemiBold] text-lg">
                        Carte de mutuelle
                      </p>
                      <p>Ajoutez votre carte de mutuelle</p>
                    </div>

                    <ValidationFileInput
                      name="insuranceCard"
                      description="Carte de mutuelle"
                    />
                  </div>

                  <div className="flex flex-col space-y-6  pt-6">
                    <div className="flex flex-col space-y-1">
                      <p className="font-[Poppins-SemiBold] text-lg">
                        Carte vitale
                      </p>
                      <p>Saisissez les informations de votre carte vitale</p>
                    </div>

                    <div className="grid sm:grid-cols-2 gap-6">
                      <FormGroup>
                        <Label htmlFor="socialSecurityPatientName">
                          Nom et prénom
                        </Label>
                        <ValidationInput
                          id="socialSecurityPatientName"
                          name="socialSecurityPatientName"
                          placeholder="Prénom"
                          disabled
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="socialSecurityBirthDate">
                          Date de naissance
                        </Label>
                        <ValidationDatePicker
                          id="socialSecurityBirthDate"
                          name="socialSecurityBirthDate"
                          placeholder="JJ/MM/AAAA"
                          disabled
                        />
                      </FormGroup>
                    </div>
                    <div className="mt-8">
                      <FormGroup>
                        <Label htmlFor="socialSecurityNumber">
                          Numéro de sécurité sociale (13 chiffres)
                        </Label>
                        <ValidationSocialSecurityNumberInput
                          id="socialSecurityNumber"
                          name="socialSecurityNumber"
                        />
                      </FormGroup>
                    </div>
                  </div>
                </Panel>
              </div>
            );
          }}
        </ValidationForm>
      </Container>
      <StickyFooter>
        <div className="flex flex-row items-center justify-between space-x-10 w-full lg:px-20">
          <Button
            size="s"
            color="transparent"
            className="flex items-center font-[Poppins-SemiBold] border-0 space-x-2 lg:px-12"
            noShadow
            onClick={() => navigate("/dashboard")}
          >
            <u>Enregistrer et continuer plus tard</u>
          </Button>
          <Button
            size="s"
            type="submit"
            form="patientForm"
            className="flex-shrink-0"
            endIcon={<CaretRight size={16} />}
          >
            Valider
          </Button>
        </div>
      </StickyFooter>
    </>
  ) : null;
}

export default PatientStep;
