import moment from "moment";

const appointements = [
  {
    patientId: 1,
    medicalStayId: 2,
    personName: "Dr L. Lebrun",
    personTitle: "Chirurgien",
    date: moment().add(7, "days").format("YYYY-MM-DD [09:00:00.000]"),
  },
  {
    patientId: 1,
    medicalStayId: 2,
    personName: "Dr L. Lebrun",
    personTitle: "Chirurgien",
    date: moment().add(60, "days").format("YYYY-MM-DD [10:00:00.000]"),
  },
  {
    patientId: 1,
    medicalStayId: 2,
    personName: "Dr L. Lebrun",
    personTitle: "Chirurgien",
    date: moment().add(90, "days").format("YYYY-MM-DD [12:00:00.000]"),
  },
];

export default appointements;
