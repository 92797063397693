import { useNavigate } from "react-router-dom";

import useMount from "~/hooks/useMount";

function LoginErrorPage() {
  const navigate = useNavigate();

  useMount(() => {
    navigate("");
  });

  return null;
}

export default LoginErrorPage;
