import React from "react";

import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import fr from "date-fns/locale/fr";
import { registerLocale } from "react-datepicker";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";

import { ProvideLayout } from "~/hooks/useLayout";

import App from "./App";
import { msalConfig } from "./auth.config.js";
import reportWebVitals from "./reportWebVitals";
import store from "./store/store";

import "./index.css";
import "react-datepicker/dist/react-datepicker.css";
import "tippy.js/dist/tippy.css";

registerLocale("fr", fr);

const root = ReactDOM.createRoot(document.getElementById("root"));

const pca = new PublicClientApplication(msalConfig);

root.render(
  <MsalProvider instance={pca}>
    <Provider store={store}>
      <ProvideLayout>
        <App />
      </ProvideLayout>
    </Provider>
  </MsalProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
