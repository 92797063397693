import React from "react";

import PropTypes from "prop-types";

function InputErrorMessage({ message }) {
  return (
    <div className="text-sm text-red-500 form-validation-error">{message}</div>
  );
}

InputErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default InputErrorMessage;
