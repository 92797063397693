const documentsList = [
  {
    patientId: 1,
    medicalStayId: 1,
    title: "Medical - Ordonnance",
    type: "ordonnance",
    fileName: "Lorem ipsum consectetur.pdf",
    size: "156ko",
  },
  {
    patientId: 1,
    medicalStayId: 1,
    title: "Médical - Consignes",
    type: "consigne",
    fileName: "Lorem ipsum consectetur.pdf",
    status: "Nouveau",
    size: "156ko",
  },
  {
    patientId: 1,
    medicalStayId: 2,
    title: "Medical - Ordonnance",
    type: "ordonnance",
    fileName: "Lorem ipsum consectetur.pdf",
    size: "156ko",
  },
  {
    patientId: 1,
    medicalStayId: 2,
    title: "Attestation sortie",
    type: "attestation",
    fileName: "attestation_sortie.pdf",
    status: "Nouveau",
    size: "156ko",
  },
  {
    patientId: 1,
    medicalStayId: 2,
    title: "Facture hospitalisation",
    type: "facture",
    fileName: "facture_20391690.pdf",
    status: "Nouveau",
    size: "156ko",
  },

  {
    patientId: 1,
    medicalStayId: 2,
    title: "Facture restauration",
    type: "facture",
    fileName: "facture_20391656.pdf",
    status: "Nouveau",
    size: "156ko",
  },
];

export default documentsList;
