import { useQueries } from "@tanstack/react-query";

export const useBatchQueries = (queries) => {
  const result = useQueries({ queries: queries });

  let isLoading = false;
  let errors = [];

  result.forEach((query) => {
    if (query.isLoading) isLoading = true;

    if (query.error) {
      errors.push(query.error);
    }
  });

  return {
    isLoading: isLoading,
    error: errors.length > 0,
    data: result.map((r) => r.data),
    errors: errors,
  };
};
