import React from "react";

import {
  faMailBulk,
  faNewspaper,
  faQuestion,
  faVideo,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import LogoWhite from "~/images/logoWhite.svg";

function PageFooter() {
  return (
    <div className="w-full">
      <div className="flex flex-col space-y-4 py-6 px-8 w-full bg-[#383838] text-white font-[Poppins-SemiBold] ">
        <p className="text-lg">Aide & questions</p>
        <div className="flex flex-col md:flex-row gap-x-16 gap-y-8 md:items-center">
          <div className="flex gap-x-2 items-center">
            <div className="flex items-center justify-center h-8 w-8 bg-[#FFC01B] rounded-full text-black">
              <FontAwesomeIcon icon={faQuestion} />
            </div>
            <p>Foire aux questions</p>
          </div>
          <div className="flex space-x-2 items-center">
            <div className="flex items-center justify-center h-8 w-8 bg-[#FFC01B] rounded-full text-black">
              <FontAwesomeIcon icon={faNewspaper} />
            </div>
            <p>Nos articles</p>
          </div>
          <div className="flex space-x-2 items-center">
            <div className="flex items-center justify-center h-8 w-8 bg-[#FFC01B] rounded-full text-black">
              <FontAwesomeIcon icon={faVideo} />
            </div>
            <p>Nos vidéos</p>
          </div>
          <div className="flex space-x-2 items-center">
            <div className="flex items-center justify-center h-8 w-8 bg-[#FFC01B] rounded-full text-black">
              <FontAwesomeIcon icon={faMailBulk} />
            </div>
            <p>Nous contacter</p>
          </div>
        </div>
      </div>
      <div className="flex flex-col md:flex-row gap-y-4  md:items-center justify-between w-full bg-[#1E1E1E] text-white py-6 px-8">
        <div className="flex flex-col space-y-1 order-last md:order-first">
          <img src={LogoWhite} alt="LogoWhite" className="w-48" />
          <p className="text-sm">
            Copyright © 2023 Elsan Care, tous droits réservés.
          </p>
        </div>
        <div className="flex md:flex-row flex-col md:items-center gap-x-4 gap-y-2">
          <p>Politique en matière de cookies</p>
          <p>Conditions d'utilisation</p>
          <p>Mentions légales</p>
        </div>
      </div>
    </div>
  );
}

export default PageFooter;
