import { useQuery } from "@tanstack/react-query";
import _ from "lodash";

import medicalStays from "~/dataSamples/MedicalStays";

export const useGetMedicalStays = () =>
  useQuery({
    queryKey: ["medicalStays"],
    queryFn: () => Promise.resolve(medicalStays),
    staleTime: 5 * 60 * 1000,
  });

export const useLoadMedicalStay = (serialNumber) =>
  useQuery({
    queryKey: ["MedicalStay", serialNumber],
    queryFn: () =>
      Promise.resolve(
        _.find(medicalStays, (m) => m.serialNumber === serialNumber)
      ),
  });
