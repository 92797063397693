import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

import StringHelper from "~/helpers/StringHelper";

import InputErrorMessage from "../inputErrorMessage/InputErrorMessage";

export const getInputClassName = (
  readOnly = undefined,
  touched = false,
  error = null,
  otherClassname = ""
) =>
  classNames(
    "w-full px-4 py-5 rounded-t-2xl  focus-visible:outline-none",
    "border-b focus:border-0 focus:border-b-2",
    "ring-0 focus:ring-0 active:ring-0 hover:ring-0",
    {
      "bg-white focus:bg-[#F8F7F6] border": readOnly !== "readonly",
      "bg-neutral-100": readOnly === "readonly",
    },
    {
      // default
      "border-gray-700 active:border-neutral-700 focus:border-neutral-700":
        !touched,
      // valid (touched + no error)
      "border-green-600 active:border-green-600 focus:border-green-600":
        touched && StringHelper.isNullOrEmpty(error),
      // in error (touched + error)
      "border-red-600 active:border-red-600 focus:border-red-600":
        touched && !StringHelper.isNullOrEmpty(error),
    },
    otherClassname
  );

function Input({
  id,
  name,
  type,
  touched,
  error,
  className,
  placeholder,
  value,
  onChange,
  autoComplete,
  noErrorMessage,
  maxLength,
  readOnly,
  disabled,
  ...fieldValidationProps
}) {
  return (
    <>
      <input
        id={id}
        name={name}
        type={type}
        placeholder={placeholder}
        className={classNames(
          "form-input",
          getInputClassName(readOnly, touched, error, className)
        )}
        {...fieldValidationProps}
        value={value}
        onChange={onChange}
        autoComplete={autoComplete}
        maxLength={maxLength}
        readOnly={readOnly}
        disabled={disabled ? "disabled" : undefined}
      />
      {touched && error && !noErrorMessage && (
        <InputErrorMessage message={error} />
      )}
    </>
  );
}

Input.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.oneOf([
    "text",
    "email",
    "file",
    "hidden",
    "number",
    "password",
    "range",
    "tel",
    "url",
  ]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  touched: PropTypes.bool,
  error: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  autoComplete: PropTypes.string,
  noErrorMessage: PropTypes.bool,
  maxLength: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

Input.defaultProps = {
  type: "text",
  value: "",
  className: "",
  placeholder: "",
  touched: false,
  error: null,
  autoComplete: "off",
  noErrorMessage: false,
  readOnly: false,
  disabled: false,
};

export default Input;
