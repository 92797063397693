import React, { useEffect } from "react";

import { Field, useField } from "formik";

import CheckboxBase from "~/components/forms_unvalidated/checkbox/Checkbox";

function ValidationCheckbox({
  id,
  name,
  value,
  checked,
  children,
  onChange,
  className,
}) {
  return (
    <Field type="checkbox" name={name} value={value} checked={checked}>
      {({ field }) => (
        <CheckboxBase
          id={id}
          {...field}
          className={className}
          onChange={(event) => {
            field?.onChange(event);
            onChange && onChange(event.target.checked);
          }}
        >
          {children}
        </CheckboxBase>
      )}
    </Field>
  );
}

ValidationCheckbox.propTypes = {
  id: CheckboxBase.propTypes.id,
  name: CheckboxBase.propTypes.name,
  value: CheckboxBase.propTypes.value,
  className: CheckboxBase.propTypes.className,
  checked: CheckboxBase.propTypes.checked,
  onChange: CheckboxBase.propTypes.onChange,
  children: CheckboxBase.propTypes.children,
};

export default ValidationCheckbox;
