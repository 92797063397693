import React from "react";

import { faAngleLeft, faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import IconButton from "~/components/iconButton/IconButton";

function CarouselArrows({ next, previous }) {
  return (
    <div className="hidden md:block absolute top-0 right-8 md:right-36">
      <div className="flex space-x-2 ">
        <IconButton onClick={previous}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </IconButton>
        <IconButton onClick={next}>
          <FontAwesomeIcon icon={faAngleRight} />
        </IconButton>
      </div>
    </div>
  );
}

export default CarouselArrows;
