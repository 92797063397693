import { useQuery } from "@tanstack/react-query";

import toDoPreOp from "~/dataSamples/ToDoPreOp";

export const useGetTodoPreOp = () =>
  useQuery({
    queryKey: ["todo"],
    queryFn: () => Promise.resolve(toDoPreOp),
    staleTime: 3600 * 24 * 1000, // 1 day
  });
