import React from "react";

import classNames from "classnames";

function Label({ className, children, ...labelProps }) {
  return (
    <label
      className={classNames("font-[Poppins-SemiBold]", className)}
      {...labelProps}
    >
      {children}
    </label>
  );
}

export default Label;
