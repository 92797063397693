export default class NumberHelper {
  static truncateDecimals = (
    number,
    numberOfDecimals,
    decimalsSeparator = ","
  ) =>
    parseFloat(number.toFixed(numberOfDecimals))
      .toString()
      .replace(/\./g, decimalsSeparator);
}
