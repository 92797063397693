import React from "react";

import { useMsal } from "@azure/msal-react";
import {
  faCalendarCheck,
  faClock,
  faHospital,
  faUserDoctor,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import { useSelector } from "react-redux";

import Block from "~/components/block/Block";
import Button from "~/components/button/Button";
import Container from "~/components/container/Container";
import IconCircle from "~/components/iconCircle/IconCircle";
import InlineDocument from "~/components/inlineDocument/InlineDocument";
import PageDataLoader from "~/components/pageDataLoader/PageDataLoader";
import Doctors from "~/images/illustrations/doctors.png";
import eSatis from "~/images/illustrations/eSatis.png";
import Question from "~/images/illustrations/question.png";
import Delivery from "~/images/photos/delivery.png";
import Survey from "~/images/photos/survey.png";
import { useGetAppointments } from "~/queries/appointmentsListQueries";
import { useGetDocumentsList } from "~/queries/documentsListQueries";

function MedicalStayPostOp({ medicalStay }) {
  const msal = useMsal();

  let activeAccount = msal.instance.getActiveAccount();
  if (!activeAccount) {
    msal.instance.setActiveAccount(msal.instance.getAllAccounts()[0]);
    activeAccount = msal.instance.getActiveAccount();
  }

  const { family_name: lastName, given_name: firstName } =
    activeAccount?.idTokenClaims;

  const { isLoading: documentsListLoading, data: documentsList } =
    useGetDocumentsList(medicalStay.id);

  const { isLoading: appointmentsLoading, data: appointments } =
    useGetAppointments(medicalStay.id);

  return (
    <PageDataLoader isLoading={documentsListLoading || appointmentsLoading}>
      <div
        className="absolute top-0 right-0 w-full md:h-[404px] h-[757px] flex justify-center
                      bg-gradient-to-bl from-[#F9B004] to-[#FBCC5C] mt-14 md:mt-8"
      >
        <Container className="h-full mt-14">
          <div className="flex items-start justify-end  h-full">
            <div className="xl:w-[532px] xl:mt-0  lg:w-[440px] w-80 lg:mt-20 mt-20 ">
              <img
                src={Doctors}
                alt="Doctors"
                className="shrink-0 object-contain  hidden md:flex p-6"
              />
            </div>
          </div>
        </Container>
      </div>
      <Container className="flex flex-col space-y-8 relative overflow-hidden mt-20 mb-20">
        <div className="flex flex-col lg:space-x-8 lg:flex-row gap-y-2 md:items-center mt-10 md:mt-16">
          <p className="text-3xl md:text-4xl font-[Poppins-Bold]">
            Bonjour {firstName} {lastName}
          </p>
        </div>
        <div className="flex lg:w-1/2">
          <Block size="s" className="flex flex-col gap-y-4">
            <p>
              Votre intervention de la hanche du{" "}
              {moment(medicalStay?.startDate).format("DD/MM/YYYY")} au{" "}
              {moment(medicalStay?.endDate).format("DD/MM/YYYY")} est terminée.
              Retrouvez désormais vos informations de suivi post-opératoire
            </p>
            <div className="flex">
              <Button size="s" color="black">
                Vous souffrez de symptômes ?
              </Button>
            </div>
          </Block>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 lg:gap-x-8 gap-x-4 lg:gap-y-8 gap-y-4">
          <Block
            size="s"
            className="flex flex-col justify-between h-full gap-y-4"
          >
            <p className="font-[Poppins-SemiBold]">
              Partagez votre expérience avec le questionnaire post-opératoire
            </p>
            <img src={Survey} alt="Survey" className="hidden md:block" />
            <Button size="s" variant="outline">
              Répondre au questionnaire
            </Button>
          </Block>
          <Block
            size="s"
            className="flex flex-col justify-between h-full gap-y-4"
          >
            <p className="font-[Poppins-SemiBold]">Mes prochains rendez-vous</p>
            <div className="flex flex-col gap-y-2 max-h-52 overflow-auto text-sm">
              {appointments?.map((appointment, index) => (
                <div
                  key={`appointment ${index}`}
                  className="flex items-end justify-between bg-[#F5F6FD] rounded-xl px-4 py-2"
                >
                  <div className="flex flex-col justify-center">
                    <p className="text-[#2C51CD]">
                      {moment(appointment?.date).format("DD/MM/YYYY")}
                    </p>
                    <p className="font-bold">
                      {appointment?.personName} - {appointment?.personTitle}
                    </p>
                  </div>
                  <div className="flex items-center gap-x-2">
                    <p> {moment(appointment?.date).format("HH:mm")}</p>
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                </div>
              ))}
            </div>
            <Button size="s" variant="outline">
              Voir tout
            </Button>
          </Block>
          <Block
            size="s"
            className="flex flex-col justify-between h-full gap-y-4"
          >
            <p className="font-[Poppins-SemiBold]">Mes documents</p>
            <div className="flex flex-col gap-y-2 max-h-52 overflow-auto text-sm">
              {documentsList?.map((document, index) => (
                <InlineDocument key={`document ${index}`} document={document} />
              ))}
            </div>
            <Button size="s" variant="outline">
              Voir tout
            </Button>
          </Block>
        </div>
        <div className="grid grid-cols-1  md:grid-cols-3 lg:gap-x-8 gap-x-4 lg:gap-y-8 gap-y-4">
          <Block
            size="s"
            className="flex xl:flex-row flex-col gap-y-4 h-full items-center justify-between gap-x-3"
          >
            <div className="flex xl:flex-row flex-col gap-y-2 items-center gap-x-2">
              <IconCircle icon={faCalendarCheck} size="xl" />
              <p className="text-sm">
                Prenez rendez-vous avec votre kinésithérapeute
              </p>
            </div>
            <div className="shrink-0">
              <Button size="s" variant="outline">
                Prendre RDV
              </Button>
            </div>
          </Block>
          <Block
            size="s"
            className="flex xl:flex-row flex-col gap-y-4 h-full items-center justify-between gap-x-3"
          >
            <div className="flex xl:flex-row flex-col gap-y-2 items-center gap-x-2">
              <IconCircle icon={faUserDoctor} size="xl" />
              <p className="text-sm">
                Votre praticien{" "}
                <span className="font-bold">{medicalStay?.practitioner}</span>
              </p>
            </div>
            <div className="shrink-0">
              <Button size="s" variant="outline">
                Contacter
              </Button>
            </div>
          </Block>
          <Block
            size="s"
            className="flex xl:flex-row flex-col gap-y-4 h-full items-center justify-between gap-x-3"
          >
            <div className="flex xl:flex-row flex-col gap-y-2 items-center gap-x-2">
              <IconCircle icon={faHospital} size="xl" />
              <p className="text-sm">
                Service Médical{" "}
                <span className="font-bold">
                  service {medicalStay?.intervention}
                </span>
              </p>
            </div>
            <div className="shrink-0">
              <Button size="s" variant="outline">
                Contacter
              </Button>
            </div>
          </Block>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 lg:gap-x-8 gap-x-4 lg:gap-y-8 gap-y-4">
          <div className="flex h-full md:col-span-2 lg:col-span-1 xl:col-span-2">
            <Block
              size="xs"
              className="flex h-full justify-between items-center "
            >
              <img
                src={eSatis}
                alt="eStatis"
                className="w-72 hidden xl:block"
              />
              <div className="flex flex-col justify-between h-full gap-y-4 px-6 py-2">
                <div className="font-[Poppins-SemiBold] text-lg">
                  Questionnaire e-Satis
                </div>
                <div className="text-sm">
                  Répondez au questionnaire de la Haute Autorité de Santé en
                  moins de 5 minutes pour évaluer votre satisfaction et
                  expérience patient au sein de notre établissement
                </div>
                <Button size="s" variant="outline">
                  En savoir plus
                </Button>
              </div>
            </Block>
          </div>
          <Block size="xs" className="h-full">
            <div className="flex flex-col justify-between pt-7 px-6 gap-y-4 lg:gap-y-0 bg-gradient-to-br from-[#2C51CD] to-[#768EDF] w-full h-full">
              <p className="text-white font-[Poppins-SemiBold] text-lg">
                Faîtes vous livrer vos médicaments à la maison
              </p>
              <img src={Delivery} alt="delivery" className="w-36 self-center" />
            </div>
          </Block>
          <Block size="xs" className="h-full">
            <div className="flex flex-col justify-between pt-7 px-6 gap-y-4 lg:gap-y-0 w-full h-full">
              <p className="font-[Poppins-SemiBold] text-lg">
                Que faire en cas de douleurs prolongées ?
              </p>
              <img src={Question} alt="question" className="w-36 self-center" />
            </div>
          </Block>
        </div>
      </Container>
    </PageDataLoader>
  );
}

export default MedicalStayPostOp;
