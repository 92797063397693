import React, { useState } from "react";

import { AnimatePresence, motion, useAnimation } from "framer-motion";
import { CaretDown, CaretUp } from "phosphor-react";

function RecapCard({ title, open = true, children }) {
  const [showMenu, setShowMenu] = useState(open);
  const controls = useAnimation();

  return (
    <div className="flex flex-col w-full rounded-xl bg-white  shadow-lg border border-gray-100 overflow-hidden ">
      <div className="flex items-center justify-between py-4 px-6">
        <p className="text-xl font-[Poppins-SemiBold]">{title}</p>

        <motion.button
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            setShowMenu(!showMenu);
            controls.start({
              rotate: showMenu ? 0 : 45,
              transition: { duration: 0.5 },
            });
          }}
          className="flex items-center justify-center bg-gray-50 rounded-xl border border-gray-200 p-2"
        >
          {showMenu ? <CaretUp size={18} /> : <CaretDown size={18} />}
        </motion.button>
      </div>
      <AnimatePresence>
        {showMenu && (
          <motion.div
            initial={{ opacity: 0, y: -10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="flex flex-col w-full"
          >
            <div className="border-b  border-gray-100" />
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-center items-start h-full gap-x-6 p-6">
              {children}
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}

export default RecapCard;
