import { createSlice } from "@reduxjs/toolkit";
import moment from "moment";

const initialState = {
  firstName: "Clara",
  lastName: "Leplat",
  avatar: "avatar.png",
  entourage: [
    {
      id: 1,
      lastName: "Perriat",
      firstName: "Laurence",
      relationshipType: { value: "parent", label: "Parent" },
      mobilePhone: "0606060606",
      email: "lperriat@gmail.com",
      addedDate: moment().subtract(7, "days").toISOString(),
    },
    {
      id: 2,
      lastName: "Clément",
      firstName: "Grihon",
      relationshipType: { value: "tutor", label: "Tutelle" },
      mobilePhone: "0631917910",
      email: "clement.grihon@gmail.com",
      addedDate: moment().subtract(7, "days").toISOString(),
    },
    {
      id: 3,
      lastName: "Doggo",
      firstName: "Houston",
      relationshipType: {
        label: "Enfant",
        value: "child",
      },
      mobilePhone: "0606060606",
      email: "woof.woof@croquettes.niche",
      addedDate: moment().subtract(7, "days").toISOString(),
    },
  ],
};

export const userSlice = createSlice({
  name: "user",
  initialState: initialState,
  reducers: {
    updateUserInfos: (state, action) => {
      if (state.firstName === "Clara") {
        state.firstName = "Kevin";
        state.lastName = "Perriat";
        state.avatar = "avatar2.png";
      } else {
        state.firstName = "Clara";
        state.lastName = "Leplat";
        state.avatar = "avatar.png";
      }
    },
  },
});

export const { updateUserInfos } = userSlice.actions;

export default userSlice.reducer;
