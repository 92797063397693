import {
  faFileMedical,
  faFileMedicalAlt,
  faSignature,
} from "@fortawesome/free-solid-svg-icons";

const toDoPreOp = [
  {
    title: "Questionnaire d'éligibilité",
    subTitle:
      "Répondez à votre questionnaire envoyé par votre équipe médicale pour valider votre éligibilité",
    icon: faSignature,
    link: "http://elsan.care",
    decoration: "bottomLeft",
  },
  {
    title: "Consignes préopératoires",
    subTitle:
      "Préparez vous en suivant les consignes fournies par votre équipe médicale",
    icon: faFileMedicalAlt,
    link: "http://elsan.care",
  },
  {
    title: "Documents à apporter",
    subTitle:
      "Préparez pour le Jour J l’ensemble des documents nécessaires à votre intervention",
    icon: faFileMedical,
    link: "http://elsan.care",
    decoration: "topRight",
  },
  {
    title: "Documents à apporter",
    subTitle:
      "Préparez pour le Jour J l’ensemble des documents nécessaires à votre intervention",
    icon: faFileMedical,
    link: "http://elsan.care",
    decoration: "topRight",
  },
];

export default toDoPreOp;
