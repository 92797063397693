import React from "react";

import { CaretDown, X } from "phosphor-react";
import PropTypes from "prop-types";
import ReactSelect, { components } from "react-select";

import { getInputClassName } from "../input/Input";
import InputErrorMessage from "../inputErrorMessage/InputErrorMessage";

const findSelectedOption = (options, fieldValue) => {
  if (fieldValue !== null) {
    for (let option of options) {
      let selected = null;

      if (option.value === fieldValue) {
        selected = option;
      } else if (option.hasOwnProperty("options")) {
        selected = findSelectedOption(option.options, fieldValue);
      }

      if (selected) {
        return selected;
      }
    }
  }

  return null;
};

const findSelectedOptions = (options, fieldValue) => {
  let selected = null;
  if (Array.isArray(fieldValue)) {
    selected = [];
    for (let value of fieldValue) {
      selected.push(findSelectedOption(options, value));
    }
  } else {
    selected = findSelectedOption(options, fieldValue);
  }

  return selected;
};

function Select({
  id,
  name,
  touched,
  error,
  placeholder,
  className,
  options,
  value,
  onChange,
  noErrorMessage,
  noOptionsMessage,
  isClearable,
  isMulti,
  isSearchable,
  ...fieldValidationProps
}) {
  return (
    <>
      <ReactSelect
        id={id}
        name={name}
        placeholder={placeholder}
        isClearable={isClearable}
        isMulti={isMulti}
        isSearchable={isSearchable}
        {...fieldValidationProps}
        styles={{
          control: () => {},
          input: (styles) => ({
            ...styles,
            border: 0,
            boxShadow: "none",
            ring: 0,
            ringShadow: "none",
            "&:focus": {
              border: 0,
              boxShadow: "none",
              ring: 0,
              outline: 0,
              ringShadow: "none",
            },
          }),

          dropdownIndicator: (provided) => ({
            ...provided,
            paddingTop: 0,
            paddingBottom: 0,
            paddingLeft: "1rem",
            paddingRight: "1rem",
          }),
          valueContainer: (provided) => ({
            ...provided,
            paddingTop: 1.25,
            paddingBottom: 1.25,
            paddingLeft: "0.5rem",
            paddingRight: "0.5rem",
            fontSize: "1rem",
          }),
          option: (styles) => ({
            ...styles,
          }),
        }}
        components={{
          Control: ({ children, ...props }) => (
            <components.Control {...props}>
              <div
                className={`form-input flex flex-row  ${getInputClassName(
                  touched,
                  error,
                  `py-0 pl-2 pr-0 min-h-[50px]`
                )}`}
              >
                {children}
              </div>
            </components.Control>
          ),
          DropdownIndicator: (props) => (
            <components.DropdownIndicator {...props}>
              <CaretDown weight="bold" />
            </components.DropdownIndicator>
          ),
          ClearIndicator: (props) => (
            <components.ClearIndicator {...props}>
              <X weight="bold" />
            </components.ClearIndicator>
          ),
        }}
        onChange={(opt) => {
          const value = Array.isArray(opt)
            ? opt.map((o) => o.value)
            : opt?.value;
          onChange && onChange(value ?? null);
        }}
        value={findSelectedOptions(options, value)}
        options={options}
        noOptionsMessage={() => noOptionsMessage}
      />
      {touched && error && !noErrorMessage && (
        <InputErrorMessage message={error} />
      )}
    </>
  );
}

Select.propTypes = {
  noOptionsMessage: PropTypes.string,
};

Select.defaultProps = {
  placeholder: "Choisir",
  noOptionsMessage: "Aucune option disponible",
};

export default Select;
