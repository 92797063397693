import React, { useEffect, useRef, useState } from "react";

import { Bed, UserRectangle, Users } from "phosphor-react";
import { useNavigate, useParams } from "react-router-dom";

import Container from "~/components/container/Container";
import PageDataLoader from "~/components/pageDataLoader/PageDataLoader";
import Stepper from "~/components/stepper/Stepper";
import usePread, { ProvidePread } from "~/contexts/usePread";
import { useBatchQueries } from "~/hooks/useBatchQueries";
import useLayout from "~/hooks/useLayout";
import { findMedicalStay, getConfig } from "~/queries/preadQueries";
import {
  getRoomOptions,
  getRoomPackages,
  getRoomPhotos,
} from "~/queries/roomReservationsQueries";

import PageHeader from "~/components/pageHeader/PageHeader";

import PatientStep, {
  patientStepPageParameters,
} from "./components/PatientStep";
import CompanionStep, {
  companionStepPageParameters,
} from "./components/CompanionStep";
import WishesStep, { wishesStepPageParameters } from "./components/WishesStep";
import PackageStep, {
  packageStepPageParameters,
} from "./components/PackageStep";

import Ellipse from "~/images/decorations/Ellipse.png";
import PlanteJaune from "~/images/decorations/PlanteJaune.png";

import ReviewModal from "./components/ReviewModal";
import classNames from "classnames";

const preAdmissionSteps = [
  {
    ...patientStepPageParameters,
    page: PatientStep,
  },
  {
    ...companionStepPageParameters,
    page: CompanionStep,
  },
  {
    ...wishesStepPageParameters,
    page: WishesStep,
  },
  {
    ...packageStepPageParameters,
    page: PackageStep,
  },
];

function InnerPage() {
  const { navigate } = useNavigate();
  const { setLayout } = useLayout();
  const { serialNumber } = useParams();

  const reviewModal = useRef();

  const { initialize, initialized } = usePread();

  const [currentStepIndex, setCurrentStepIndex] = useState(0);

  const { isLoading, error, data } = useBatchQueries([
    findMedicalStay(serialNumber),
    getConfig(serialNumber),
    getRoomOptions,
    getRoomPackages,
    getRoomPhotos,
  ]);

  useEffect(() => {
    setLayout({
      title: "Informations administratives",
    });
  }, [currentStepIndex, setLayout]);

  useEffect(() => {
    if (!isLoading && !error) {
      initialize(data[0], data[1], data[2], data[3], data[4]);
    }
  }, [isLoading, error, initialize, data]);

  const PageComponent = preAdmissionSteps[currentStepIndex].page;

  return (
    <PageDataLoader isLoading={isLoading} error={error}>
      {initialized && (
        <>
          <div
            className="absolute top-0 right-0 w-full h-[404px] flex justify-center
                      bg-gradient-to-bl from-[#F9B004] to-[#FBCC5C] "
          >
            <Container className="h-full ">
              <div
                className={classNames(
                  "flex items-end justify-end 2xl:justify-between h-full -ml-28 ",
                  {
                    "2xl:-mr-40":
                      !preAdmissionSteps[currentStepIndex].imageNoShift,
                    "": preAdmissionSteps[currentStepIndex].imageNoShift,
                  }
                )}
              >
                <img
                  src={PlanteJaune}
                  alt="PlanteJaune"
                  className="w-44 2xl:flex hidden"
                />
                <div className="flex items-end ">
                  <img
                    src={Ellipse}
                    alt="Ellipse"
                    className="xl:-mr-16 xl:flex hidden"
                  />
                  <div className="lg:w-96">
                    <img
                      src={preAdmissionSteps[currentStepIndex].image}
                      alt="Illustration"
                      className="shrink-0 object-contain lg:h-72 h-44 lg:mb-0 mb-20 max-w-sm hidden md:flex"
                    />
                  </div>
                </div>
              </div>
            </Container>
          </div>
          <Container className="z-10">
            <div className="lg:w-2/3 lg:mt-36 mt-28">
              <Stepper
                steps={[
                  {
                    text: "Informations",
                    icon: UserRectangle,
                  },
                  {
                    text: "Entourage",
                    icon: Users,
                  },
                  {
                    text: "Souhaits",
                    icon: Bed,
                  },
                  {
                    text: "Confort",
                    icon: Bed,
                  },
                ]}
                activeIndex={currentStepIndex}
              />
            </div>

            <PageHeader title={preAdmissionSteps[currentStepIndex].title}>
              {preAdmissionSteps[currentStepIndex].subTitle}
            </PageHeader>
          </Container>

          <div className="flex flex-col items-center w-full z-10">
            <PageComponent
              medicalStaySerialNumber={serialNumber}
              onSubmitted={() => setCurrentStepIndex(currentStepIndex + 1)}
              onPrevious={() =>
                currentStepIndex === 0
                  ? navigate(-1)
                  : setCurrentStepIndex(currentStepIndex - 1)
              }
            />
          </div>

          <ReviewModal ref={reviewModal} />
        </>
      )}
    </PageDataLoader>
  );
}

const PreadmissionPage = (props) => {
  return (
    <ProvidePread>
      <InnerPage {...props} />
    </ProvidePread>
  );
};

export default PreadmissionPage;
