import React from "react";

import classNames from "classnames";
import PropTypes from "prop-types";

const maxSM = "sm:max-w-[540px]";
const maxMD = "md:max-w-[720px]";
const maxLG = "lg:max-w-[960px]";
const maxXL = "xl:max-w-[1160px]";
const max2XL = "2xl:max-w-[1200px]";

function Container({ size, className, children }) {
  return (
    <div
      className={classNames("px-4 sm:p-0 overflow-visible", className, {
        [`w-full max-w-full ${maxSM} ${maxMD} ${maxLG} ${maxXL} ${max2XL}`]:
          size === "",
        [`w-full ${maxSM} ${maxMD} ${maxLG} ${maxXL} ${max2XL}`]: size === "sm",
        [`w-full ${maxMD} ${maxLG} ${maxXL} ${max2XL}`]: size === "md",
        [`w-full ${maxLG} ${maxXL} ${max2XL}`]: size === "lg",
        [`w-full ${maxXL} ${max2XL}`]: size === "xl",
        [`w-full ${max2XL}`]: size === "xxl",
      })}
    >
      {children}
    </div>
  );
}

Container.propTypes = {
  size: PropTypes.oneOf(["", "sm", "md", "lg", "xl", "xxl"]),
  className: PropTypes.string,
  children: PropTypes.node,
};

Container.defaultProps = {
  size: "",
  className: "",
};

export default Container;
