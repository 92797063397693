import CarrouselImg1 from "~/images/Carrousel1.png";
import CarrouselImg2 from "~/images/Carrousel2.png";
import CarrouselImg3 from "~/images/Carrousel3.png";
import CarrouselImg4 from "~/images/Carrousel4.png";

// To make dynamic with a CMS solution
const news = [
  {
    title: "Actualité chirurgie",
    image: CarrouselImg1,
    description: "L’école des patients à la Polyclinique de Limoges",
    link: "http://elsan.care",
  },
  {
    title: "Coronavirus",
    image: CarrouselImg2,
    description: "Rhume ou Covid, comment faire la différence ?",
    link: "http://elsan.care",
  },
  {
    title: "Actualité radiologie",
    image: CarrouselImg3,
    description: "Installation de l’IRM à la Clinique Saint-Michel",
    link: "http://elsan.care",
  },
  {
    title: "Conseils et prévention",
    image: CarrouselImg4,
    description: "Découvrez l'ouvrage 'Ma cuisine bretonne'",
    link: "http://elsan.care",
  },
];

export default news;
